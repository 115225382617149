import api from './auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class LockerApi {
  getFacility() {
    return api.post('/admin/facility/get', obj);
  }

  getemail() {
    return api.post('/admin/getemail', obj);
  }
  getlocker() {
    return api.post('/locker/getlockerall', obj);
  }
  getfilterdata(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/getlockertype', datafull);
  }
  gettypelocker() {
    return api.get('/utils/lockertypes');
  }
  getfloor() {
    return api.post('/room/floor', obj);
  }
  createlocker(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/add', datafull);
  }
  updatelocker(data) {
    return api.post('/locker/edit', data);
  }
  deletelocker(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/delete', datafull);
  }
  adduser(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/employeeadd', datafull);
  }
  removeUser(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/employeeremove', datafull);
  }
  openlocker(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/unlock', datafull);
  }
  deletezone(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/deletelockerzone', datafull);
  }
  createzone(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/addlockerzone', datafull);
  }
  updatezone(data) {
    const datafull = { ...data, ...obj };
    return api.post('/locker/updatelockerzone', datafull);
  }
  getzone() {
    return api.post('/locker/getzone', obj);
  }
}

export default new LockerApi();
