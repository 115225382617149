import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import ProtectedRoute from './components/protectedRouter';
import Error from './pages/Error';
import Dashboard from './pages/Dashboard';
import ForegetPassword from './pages/ForegetPassword';
import UserManagement from './pages/UserManagement';
import UserAuthentication from './pages/UserAuthentication';
import UserApproval from './pages/UserApproval';
import MasterFacility from './pages/MasterFacility';
import Report from './pages/Report';
import LiveCapture from './pages/LiveCapture';
import BookingManagement from './pages/BookingManagement';
import EmailLog from './pages/EmailLog';
import Locker from './pages/Locker';
import Panel from './pages/Panel';
import Desk from './pages/Desk';
import DeskManagement from './pages/DeskManagement';
import VisterManagement from './pages/VisterManagement';
import { createBrowserHistory } from 'history';
import FullAttendance from 'pages/fullattendanceemployee';
import VisterManagementNon from 'pages/visitornonapproval';

export const history = createBrowserHistory();

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route exact path='/' element={<Dashboard />}></Route>
            <Route
              exact
              path='/user-management'
              element={<UserManagement />}
            ></Route>
            <Route
              exact
              path='/vister-management'
              element={<VisterManagement />}
            ></Route>
            <Route
              exact
              path='/visitor-approval'
              element={<VisterManagementNon />}
            ></Route>
            <Route
              exact
              path='/user-authentication'
              element={<UserAuthentication />}
            ></Route>
            <Route
              exact
              path='/user-approval'
              element={<UserApproval />}
            ></Route>
            <Route
              exact
              path='/master-facility'
              element={<MasterFacility />}
            ></Route>
            <Route
              exact
              path='/full-attendance'
              element={<FullAttendance />}
            ></Route>
            <Route exact path='/locker' element={<Locker />}></Route>
            <Route exact path='/panel' element={<Panel />}></Route>
            <Route exact path='/desk' element={<Desk />}></Route>
            <Route exact path='/reports' element={<Report />}></Route>
            <Route exact path='/live-capture' element={<LiveCapture />}></Route>
            <Route
              exact
              path='/booking-management'
              element={<BookingManagement />}
            ></Route>
            <Route
              exact
              path='/desk-management'
              element={<DeskManagement />}
            ></Route>
            <Route exact path='/email-log' element={<EmailLog />}></Route>
          </Route>

          <Route exact path='/login' element={<Login />}></Route>
          <Route
            exact
            path='/forget-password'
            element={<ForegetPassword />}
          ></Route>
          <Route exact path='*' element={<Error />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
