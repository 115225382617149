import api from './auth';
var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class HotdeskingBookingApi {
  getfloor() {
    return api.post('/hotdeskingAdmin/getfloor', obj);
  }
  getzone(data) {
    const datafull = { ...data, ...obj };
    return api.post('/hotdeskingAdmin/getzone', datafull);
  }
  getbooking() {
    return api.post('/hotdeskingAdmin/getbooking', obj);
  }
  getemail() {
    return api.post('/admin/getemail', obj);
  }

  getavailable(data) {
    const datafull = { ...data, ...obj };
    return api.post('/hotdeskingAdmin/available', datafull);
  }
  seatbooking(data) {
    const datafull = { ...data, ...obj };
    return api.post('/hotdeskingAdmin/seatbooking', datafull);
  }

  seatbookingcancel(data) {
    const datafull = { ...data, ...obj };
    return api.post('/hotdeskingAdmin/cancelbooking', datafull);
  }
}

export default new HotdeskingBookingApi();
