import api from 'api/auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class UserManagement {
  getuser() {
    return api.post('/employee/get', obj);
  }

  getFacility() {
    return api.post('/admin/facility/get', obj);
  }

  getEmployeerole() {
    return api.get('/utils/employeerole');
  }
  getDesignation() {
    return api.get('/utils/designation');
  }
  getDepartment() {
    return api.get('/utils/department');
  }
  EmployeeDelete(data) {
    const datafull = { ...data, ...obj };
    return api.post('/employee/delete', datafull);
  }

  EmployeeAdd(data) {
    return api.post('/employee/add', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  UpdateEmployee(data) {
    return api.post('/employee/edit', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new UserManagement();
