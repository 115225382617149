// // import { format } from 'date-fns'
import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const COLUMNS = [
  {
    Header: 'S.No',
    accessor: '_id',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },
  {
    Header: 'Room Name',
    accessor: 'room_name',
    sticky: 'left',
  },
  {
    Header: 'No Pax',
    accessor: 'no_of_pax',
  },
  {
    Header: 'List Pax',
    accessor: 'list_of_pax',
  },
  {
    Header: 'Organizer',
    accessor: 'organizer_email',
    sticky: 'left',
  },
  {
    Header: 'Booked Date',
    accessor: 'booking_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Reserved Date',
    accessor: 'reserved_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Start Time',
    accessor: 'start_time',
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
  },

  {
    Header: 'Status',
    accessor: 'current_status',
    Cell: (s) => {
      return (
        <>
          <span
            className={
              s.value === 'Completed'
                ? 'greencolor'
                : s.value === 'Reserved'
                ? 'orangecolor'
                : s.value === 'Occupied'
                ? 'redcolor'
                : null
            }
          ></span>
          <span></span>
          {s.value}
        </>
      );
    },
  },
];

export const Desk = [
  {
    Header: 'S.No',
    accessor: '_id',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },
  {
    Header: 'Floor Name',
    accessor: 'floor_name',
    sticky: 'left',
  },
  {
    Header: 'Zone Name',
    accessor: 'zone_name',
  },
  {
    Header: 'Table Name',
    accessor: 'table_name',
  },
  {
    Header: 'Seat Name',
    accessor: 'seat_name',
    sticky: 'left',
  },
  {
    Header: 'Booked Date',
    accessor: 'booking_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Reserved Date',
    accessor: 'reserved_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Start Time',
    accessor: 'start_time',
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
  },

  {
    Header: 'Status',
    accessor: 'current_status',
    Cell: (s) => {
      return (
        <>
          <span
            className={
              s.value === 'Completed'
                ? 'greencolor'
                : s.value === 'Reserved'
                ? 'orangecolor'
                : s.value === 'Occupied'
                ? 'redcolor'
                : null
            }
          ></span>
          {s.value}
        </>
      );
    },
  },
];



export const Reports = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Room Name',
    accessor: 'room_name',
    sticky: 'left',
  },
  {
    Header: 'No-Pack',
    accessor: 'no_of_pax',
    sticky: 'left',
  },
  {
    Header: 'List Pax',
    accessor: 'list_of_pax',
  },
  {
    Header: '	Organiser Name',
    accessor: 'organiser_name',
    sticky: 'left',
  },
  {
    Header: 'Booking Date',
    accessor: 'booking_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Reserved Date',
    accessor: 'reserved_date',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
  {
    Header: 'Start Time',
    accessor: 'start_time',
    sticky: 'left',
  },
  {
    Header: 'End Time',
    accessor: 'end_time',
    sticky: 'left',
  },
  {
    Header: 'Current Status',
    accessor: 'current_status',
    sticky: 'left',
  },
];

export const invoice = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Room Name',
    accessor: 'room_name',
    sticky: 'left',
  },
  {
    Header: 'Floor No',
    accessor: 'floor_name',
    sticky: 'left',
  },

  {
    Header: 'Status',
    accessor: 'status',
    sticky: 'left',
  },
  {
    Header: 'Current Status',
    accessor: 'current_status',
    sticky: 'left',
    Cell: (s) => {
      return (
        <>
          <span
            className={
              s.value === 'Online'
                ? 'greencolor'
                : s.value === 'Offline'
                ? 'redcolor'
                : null
            }
          ></span>
          <span></span>
          {s.value}
        </>
      );
    },
  },
];

export const employeeattendance = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Name',
    accessor: 'employee_name',
    sticky: 'left',
    Cell: (row) => {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Avatar
              alt='Remy Sharp'
              src={row.row.original.employee_image}
              sx={{ width: 36, height: 36 }}
            />
            {/* <img
              alt='avatar'
              height={0}
              width={0}
              loading='lazy'
              style={{ borderRadius: '50%' }}
            /> */}
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{row.row.original.employee_name}</span>
          </Box>
        </>
      );
    },
  },

  // {
  //   Header: 'Gender',
  //   accessor: 'employee_gender',
  //   sticky: 'left',
  // },
  {
    Header: 'Phone no',
    accessor: 'employee_phone_no',
    sticky: 'left',
  },
  {
    Header: 'Department',
    accessor: 'employee_department',
    sticky: 'left',
  },

  {
    Header: 'Authentication',
    accessor: 'auth_type',
    sticky: 'left',
  },
  {
    Header: 'Door Name',
    accessor: 'device_name',
    sticky: 'left',
  },
  {
    Header: 'Door Type',
    accessor: 'type',
    sticky: 'left',
  },
  {
    Header: 'Date',
    accessor: 'createdDate',
    sticky: 'left',
  },
  {
    Header: 'Time',
    accessor: 'createdTime',
    sticky: 'left',
  },
];
export const Companies_data = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Companies ',
    accessor: 'name',
  },
  {
    Header: 'Logins',
    accessor: 'logins',
  },
  {
    Header: 'Subcripction Plan',
    accessor: 'subscription_plan',
  },
  {
    Header: 'Purchased On ',
    accessor: 'purchased_on',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Renewal On',
    accessor: 'renewal_on',
  },
];

// export const EmailLogs = [
//   {
//     Header: 'S.No',
//     accessor: 'id',
//     width: 10,
//   },

//   {
//     Header: 'Email',
//     accessor: 'email',
//     width: 80,
//   },
//   {
//     Header: 'Subject',
//     accessor: 'subject',
//   },
//   {
//     Header: 'Date',
//     accessor: 'date',
//     // Cell: ({ value }) => {
//     //   return format(new Date(value), 'dd/MM/yyyy')
//     // }
//   },

// ]

export const SuperAdmin = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Name',
    accessor: 'name',
    sticky: 'left',
  },
  {
    Header: 'Email id',
    accessor: 'email',
    sticky: 'left',
  },
  {
    Header: 'Purchased On',
    accessor: 'createdAt',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
];

export const Plans = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Plan Name',
    accessor: 'name',
    sticky: 'left',
  },
  {
    Header: 'Strip ID',
    accessor: 'strip_id',
    sticky: 'left',
  },
  {
    Header: 'User count',
    accessor: 'user_count',
    sticky: 'left',
  },
  {
    Header: 'Price',
    accessor: 'price',
    sticky: 'left',
  },
  {
    Header: 'Status',
    accessor: 'status',
    // Cell: ({ value }) => {
    //   return format(new Date(value), 'dd/MM/yyyy')
    // }
  },
];

export const facilitylist = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Facility',
    accessor: 'facility',
    sticky: 'left',
  },
  {
    Header: 'Authentication ',
    accessor: 'Authentication ',
    sticky: 'left',
  },
];

export const authList = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'Authentication ',
    accessor: 'name',
    sticky: 'left',
  },
];

export const UserList = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'name ',
    accessor: 'name',
    sticky: 'left',
  },
  {
    Header: 'designation ',
    accessor: 'designation',
    sticky: 'left',
  },
  {
    Header: 'department ',
    accessor: 'department',
    sticky: 'left',
  },
  {
    Header: 'phone_no ',
    accessor: 'phone_no',
    sticky: 'left',
  },
  {
    Header: 'RFID ',
    accessor: 'rfid',
    sticky: 'left',
  },
  {
    Header: 'role ',
    accessor: 'role',
    sticky: 'left',
  },
  {
    Header: 'gender',
    accessor: 'gender',
    sticky: 'left',
  },
  {
    Header: 'valid_image',
    accessor: 'trained_image',
    sticky: 'left',
    Cell: (s) => {
      return (
        <>
          <span
            className={
              s.value === true
                ? 'greencolor'
                : s.value === false
                ? 'redcolor'
                : null
            }
          ></span>
          <span></span>
          {s.value === true ? 'Accept' : 'Reject'}
        </>
      );
    },
  },
];

export const Visitorlist = [
  {
    Header: 'S.No',
    accessor: '',
    Cell: (row) => {
      return <div>{row.row.id * 1 + 1}</div>;
    },
  },

  {
    Header: 'name ',
    accessor: 'name',
    sticky: 'left',
  },
  {
    Header: 'designation ',
    accessor: 'designation',
    sticky: 'left',
  },
  {
    Header: 'department ',
    accessor: 'department',
    sticky: 'left',
  },
  {
    Header: 'phone_no ',
    accessor: 'phone_no',
    sticky: 'left',
  },
  {
    Header: 'RFID ',
    accessor: 'rfid',
    sticky: 'left',
  },

   {
    Header: 'startdate ',
    accessor: 'start_date',
    sticky: 'left',
  },
   {
    Header: 'enddate ',
    accessor: 'end_date',
    sticky: 'left',
  },
  {
    Header: 'gender',
    accessor: 'gender',
    sticky: 'left',
  },
  {
    Header: 'valid_image',
    accessor: 'trained_image',
    sticky: 'left',
    Cell: (s) => {
      return (
        <>
          <span
            className={
              s.value === true
                ? 'greencolor'
                : s.value === false
                ? 'redcolor'
                : null
            }
          ></span>
          <span></span>
          {s.value === true ? 'Accept' : 'Reject'}
        </>
      );
    },
  },
];