import api from './auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class EmployeeAttenApi {
  today_attendance() {
    return api.post('/livecapture/get', obj);
  }
  full_attendance() {
    return api.post('/employee/employeeattendance', obj);
  }
}

export default new EmployeeAttenApi();
