import '../Styles/global.css';
import '../Styles/Companies.css';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import { invoice } from '../helper/columns';
// import { GlobalFilter } from "../components/table_filter/GlobalFilter";
import { ColumnFilter } from '../components/table_filter/ColumnFilter';
import { Link } from 'react-router-dom';
import '../Styles/Invoices.css';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOutlineDoDisturbAlt } from 'react-icons/md';
import { BiDetail } from 'react-icons/bi';
import InputWithLable from '../components/input/InputWithLable';
import meetingroom from 'api/meetingroom';
import { Toast, popUp } from '../components/Swal/Swal';
import { sockets } from 'apisocket/socket';

function MasterFacility() {
  const renderAfterCalled = useRef(false);

  const [showRoom, setshowRoom] = useState(false);
  const [RoomName, setRoomName] = useState('');
  const [FloorName, setFloorName] = useState('');
  const [Description, setDescription] = useState('');
  const [Password, setPassword] = useState('');
  const [Email, setEmail] = useState('');
  const [Packs, setPacks] = useState('');
  const [Stopic, setStopic] = useState('');
  const [Ptopic, setPtopic] = useState('');
  const [Dtopic, setDtopic] = useState('');
  const [Msg, setMsg] = useState('');
  const [room, setroom] = useState(false);
  const [roomid, setroomid] = useState(false);
  const [Floor, setFloor] = useState([]);
  const [Data, setData] = useState([]);
  const [facility, setfacility] = useState([]);
  const [access, setaccess] = useState([]);

  const columns = useMemo(() => invoice, []);
  const data = useMemo(() => Data, [Data]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;

  const EditRoom = async (d) => {
    setroom(true);
    console.log(d);
    setRoomName(d.room_name);
    setFloorName(d.floor_name);
    setDescription(d.description);
    setPassword(d.password);
    setEmail(d.device_id);
    setPacks(d.no_of_pax);
    setStopic(d.subscribes_topic);
    setPtopic(d.publish_topic);
    setDtopic(d.device_topic);
    setMsg(d.message);
    setroomid(d._id);
    setaccess(d.authentication);
    const data = facility.filter((a, i) => {
      return d?.authentication[i]?.auth_name === a?.auth_name;
    });

    console.log(data);
    //  "authentication": [
    setshowRoom(true);
  };

  const cancel = () => {
    setshowRoom(false);
    setRoomName('');
    setFloorName('');
    setDescription('');
    setPassword('');
    setEmail('');
    setPacks('');
    setStopic('');
    setPtopic('');
    setDtopic('');
    setMsg('');
    setroomid('');
  };
  //

  //https://api.chopeai.com/api/invoice/get

  const getData1 = async () => {
    await meetingroom
      .getFacility()
      .then(function (response) {
        console.log(response?.data?.data?.facility ?? []);
        var data = response.data?.data?.facility?.filter((d) => {
          return d.facility_name === 'meeting room';
        });
        setfacility(data[0].authentication);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getData = async () => {
    await meetingroom
      .getroom()
      .then(function (response) {
        setData(response.data?.data?.room);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getFoolr = async () => {
    await meetingroom
      .getfloor()
      .then(function (response) {
        setFloor(response.data?.data?.floor);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const activeComp = async (d) => {
    var obj = {
      room_id: d._id,
    };
    await meetingroom
      .roomenable(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const disableComp = async (d) => {
    var obj = {
      room_id: d._id,
    };
    await meetingroom
      .roomdisable(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const deleteComp = async (d) => {
    console.log(d);
    var obj = {
      room_id: d._id,
    };
    await meetingroom
      .roomdelete(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const createRoom = async () => {
    var facility = document.getElementsByName('facility');
    const access = [];
    console.log(facility[0].id);
    facility.forEach((d) => {
      if (d.checked) {
        var dd = {
          auth_id: d.id,
          auth_name: d.value,
        };
      }
      // console.log(dd);
      access.push(dd);
    });

    var obj = {
      room_name: RoomName,
      floor_name: FloorName,
      no_of_pax: Packs,
      device_id: Email,
      password: Password,
      description: Description,
      device_topic: Dtopic,
      publish_topic: Ptopic,
      message: Msg,
      subscribes_topic: Stopic,
      authentication: access,
    };
    await meetingroom
      .roomcreate(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
        setshowRoom(false);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };
  const UpdateRoom = async () => {
    var facility = document.getElementsByName('facility');
    const access = [];
    console.log(facility[0].id);
    facility.forEach((d) => {
      console.log(d);
      if (d.checked) {
        var dd = {
          auth_id: d.id,
          auth_name: d.value,
        };
        access.push(dd);
      }
      // console.log(dd);
    });

    console.log(access);
    var obj = {
      room_id: roomid,
      room_name: RoomName,
      floor_name: FloorName,
      list_of_pax: Packs,
      device_id: Email,
      password: Password,
      description: Description,
      device_topic: Dtopic,
      publish_topic: Ptopic,
      message: Msg,
      subscribes_topic: Stopic,
      authentication: access,
    };
    await meetingroom
      .roomupdate(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
        setshowRoom(false);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const AddRoom = () => {
    setshowRoom(true);
    setroom(false);
  };
  useEffect(() => {
    if (window.location.pathname === '/master-facility') {
      document.getElementById('MeetingRoom').style.backgroundColor = '#394A7A';
    }

    if (!renderAfterCalled.current) {
      getData();
      getData1();
      getFoolr();
      sockets.off('paneltracking').on('paneltracking', async (data) => {
        if (data) {
          console.log('reload panel status');
          getData();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <>
      <div className='appHeader'>
        <p>Facility Master</p>
      </div>
      <div className='management-flex'>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/master-facility'>
              <button
                id='MeetingRoom'
                className='add-new report-btn-item blue-btn-box active'
              >
                Meeting Room
              </button>
            </Link>
            <Link to='/locker'>
              <button
                id='Locker'
                className='add-new report-btn-item blue-btn-box active'
              >
                Locker
              </button>
            </Link>
            <Link to='/panel'>
              <button
                id='Panel'
                className='add-new report-btn-item blue-btn-box active'
              >
                Door Panel
              </button>
            </Link>
            <Link to='/desk'>
              <button
                id='desk'
                className='add-new report-btn-item blue-btn-box active'
              >
                Hot Desking
              </button>
            </Link>
            {/* <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button>
                    <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button>
                    <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button> */}
          </div>
        </div>
      </div>
      <div className='table-container border-radius5px box-shadow'>
        <div className='manament-table-header'>
          <span> Meeting Room</span>
          <div className='btn-divder'>
            {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
            <button className='add-new-btn' onClick={AddRoom}>
              Add Room
            </button>
          </div>
        </div>
        {/* divder */}
        <div className='justify-center'>
          <span className='divder'></span>
        </div>

        <div className='table-container'>
          <>
            <table {...getTableProps()} id='test'>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                        <span
                          className='table-asc-dec'
                          onClick={() => column.toggleSortBy()}
                        >
                          <span>{column.render('Header')}</span>
                          <span>
                            {' '}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </span>
                      </th>
                    ))}
                    <th>
                      <div className='margintop2rem'>Action</div>
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      <td className='action-table-btns'>
                        <BiEditAlt
                          {...row.getRowProps()}
                          onClick={() => EditRoom(row.original)}
                          className='cursor-pointer'
                        />
                        <AiOutlineDelete
                          onClick={() => deleteComp(row.original)}
                          className='cursor-pointer'
                        />
                        <MdOutlineDoDisturbAlt
                          onClick={() => disableComp(row.original)}
                          className='cursor-pointer'
                        />
                        <BiDetail
                          onClick={() => activeComp(row.original)}
                          className='cursor-pointer'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='pagination-section'>
              <div className='pagination-page-section'>
                <div>
                  <span>
                    Page
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
                <span>
                  | Go to page:{' '}
                  <input
                    type='number'
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: '50px' }}
                  />
                </span>{' '}
              </div>

              <div className='pagination-btn-section'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <FiChevronsLeft />
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <FiChevronLeft /> <span>Previous</span>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <span>Next</span> <FiChevronRight />
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <FiChevronsRight />
                </button>
              </div>

              <div className='pagination-select-section'>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 15, 20, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        </div>
      </div>

      {showRoom && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Edit Room</h3>
              <div className='company-detail-list'>
                <InputWithLable
                  placeholder='Meeting Room Name'
                  callback={(event) => setRoomName(event.target.value)}
                  value={RoomName}
                />
                {/* <InputWithLable placeholder='Floor: ' callback={(event)=>setFloorName(event.target.value)} value={FloorName}/> */}
                <div className='input-withlable-container '>
                  <span>Floor</span>

                  <div className='input-container'>
                    {/* <span className='right-icon simple-right-icon '><AiFillCaretDown/></span> */}
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setFloorName(e.target.value)}
                      value={FloorName}
                    >
                      <option value='' disabled selected>
                        Floor
                      </option>

                      {Floor.map((d, i) => {
                        return (
                          <option key={i} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <SimpleDropDown placeholder='Country'/> */}
                </div>
                <InputWithLable
                  placeholder='Description:'
                  callback={(event) => setDescription(event.target.value)}
                  value={Description}
                />
                <InputWithLable
                  placeholder='Device Id: '
                  callback={(event) => setEmail(event.target.value)}
                  value={Email}
                />
                <InputWithLable
                  placeholder='Password:'
                  callback={(event) => setPassword(event.target.value)}
                  value={Password}
                />
                <InputWithLable
                  placeholder='No of Packs: '
                  callback={(event) => setPacks(event.target.value)}
                  value={Packs}
                />
                <InputWithLable
                  placeholder='subscribe Topic:'
                  callback={(event) => setStopic(event.target.value)}
                  value={Stopic}
                />
                <InputWithLable
                  placeholder='Publish topic:'
                  callback={(event) => setPtopic(event.target.value)}
                  value={Ptopic}
                />
                <InputWithLable
                  placeholder='Device Topic: '
                  callback={(event) => setDtopic(event.target.value)}
                  value={Dtopic}
                />
                <InputWithLable
                  placeholder='Message: '
                  callback={(event) => setMsg(event.target.value)}
                  value={Msg}
                />
                <div className='facility-section'>
                  <span className='header'>Authentication</span>
                  <div className='flex-column-with-row'>
                    <div>
                      {!room
                        ? facility.map((d, i) => {
                            return (
                              <>
                                <div className='align-center'>
                                  <input
                                    type='checkbox'
                                    id={d.auth_id}
                                    name='facility'
                                    value={d.auth_name}
                                    onChange={(e) => console.log(e)}
                                  />
                                  <label htmlFor='vehicle3'>
                                    {d.auth_name}
                                  </label>
                                </div>
                              </>
                            );
                          })
                        : null}

                      {access.map((a, i) => {
                        return (
                          <div className='align-center' key={i}>
                            <input
                              type='checkbox'
                              checked
                              id={a.auth_id}
                              name='facility'
                              value={a.auth_name}
                              onChange={(e) => console.log(e)}
                            />
                            <label htmlFor='vehicle3'>{a.auth_name}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>
                    Cancel
                  </button>
                  {!room ? (
                    <button className='create-btn' onClick={createRoom}>
                      Create
                    </button>
                  ) : (
                    <button className='create-btn' onClick={UpdateRoom}>
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MasterFacility;
