/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import dashboard from 'api/dashboard';
import { popUp } from '../components/Swal/Swal';
import Piechart1 from 'components/chart/piechart1';
import { sockets } from 'apisocket/socket';
import Chart from 'react-apexcharts';
import 'Styles/Dashboard1.css';
import Piechartper from 'components/chart/piechartper';

function Dashboard() {
  const renderAfterCalled = useRef(false);
  const [meetingroom, setMeetingroom] = useState({
    Available: 0,
    Occupied: 0,
  });
  const [hotdesking, setHotdesking] = useState({
    Available: 0,
    Occupied: 0,
  });
  const [visitor, setVisitor] = useState({
    in: 0,
    out: 0,
    total: 0,
  });
  const [locker, setLocker] = useState({
    Available: 0,
    Occupied: 0,
  });
  const [staff, setStaff] = useState({
    present: 0,
    totalstaff: 0,
  });
  const [employeeattendance, setEmployeeattendance] = useState({
    present: 0,
    absent: 0,
    total: 0,
  });
  const [product, setProduct] = useState([
    {
      name: 'Present',

      data: [],
    },
    {
      name: 'Absent',
      data: [],
    },
  ]);

  const [option, setOption] = useState({
    tooltip: {
      theme: 'dark',
    },
    title: {
      text: 'Employee Attendance Week Chart',
      style: {
        color: '#ffffff',
        fontSize: '19px',
      },
    },
    chart: {
      toolbar: {
        show: true,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },

    dataLabels: {
      enabled: false,
      colors: ['#63CDFF', '#FF3434'],
    },
    legend: {
      show: true,

      labels: {
        colors: '#ffffff',
      },
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#63CDFF', '#FF3434'],

    xaxis: {
      // axisBorder: {
      //   show: true,
      //   color: '#FF1654',
      // },
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
      title: {
        text: 'Week',
        style: {
          color: '#FF3434',
          fontSize: '1rem',
        },
      },

      categories: [],
    },

    yaxis: {
      title: {
        text: 'Count',
        style: {
          color: '#247BA0',
          fontSize: '1rem',
        },
      },
      labels: {
        style: {
          colors: '#ffffff',
        },
      },
    },
  });

  const getstaffpromise = async () => {
    await dashboard
      .staffpromise()
      .then(function (response) {
        setStaff({
          ...staff,
          present: response?.data?.data?.promise_today?.present ?? 0,
          totalstaff: response?.data?.data?.promise_today?.totalstaff ?? 0,
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const getmeetingroom = async () => {
    await dashboard
      .meetingroom()
      .then(function (response) {
        setMeetingroom({
          ...meetingroom,
          Available: response?.data?.data?.meeting_room?.Available ?? 0,
          Occupied: response?.data?.data?.meeting_room?.Occupied ?? 0,
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const gethotdesking = async () => {
    await dashboard
      .hotdesking()
      .then(function (response) {
        setHotdesking({
          ...hotdesking,
          Available: response?.data?.data?.hotdesking?.Available ?? 0,
          Occupied: response?.data?.data?.hotdesking?.Occupied ?? 0,
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const getlocker = async () => {
    await dashboard
      .locker()
      .then(function (response) {
        setLocker({
          ...locker,
          Available: response?.data?.data?.locker?.Available ?? 0,
          Occupied: response?.data?.data?.locker?.Occupied ?? 0,
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };

  const getvisitor = async () => {
    await dashboard
      .visitor()
      .then(function (response) {
        setVisitor({
          ...visitor,
          in: response?.data?.data?.visitor?.in ?? 0,
          out: response?.data?.data?.visitor?.out ?? 0,
          total: response?.data?.data?.visitor?.total ?? 0,
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const getemployeeattendance = async () => {
    await dashboard
      .getemployeeattedance()
      .then(function (response) {
        setEmployeeattendance({
          ...employeeattendance,
          present: response?.data?.data?.attendance?.present ?? 0,
          absent: response?.data?.data?.attendance?.absent ?? 0,
          total: response?.data?.data?.attendance?.total ?? 0,
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const getemployeeattendancegraph = async () => {
    await dashboard
      .getemployeeattedancegraph()
      .then(function (response) {
        const dateresponse = response?.data?.data?.attendance ?? [];
        if (dateresponse.length > 0) {
          let result = dateresponse.map((a) => a.date);
          setOption({
            ...option,
            xaxis: {
              categories: result,
            },
          });
          let present = dateresponse.map((a) => a.present);
          let absent = dateresponse.map((a) => a.absent);

          setProduct([
            {
              name: 'Present',

              data: present,
            },
            {
              name: 'Absent',
              data: absent,
            },
          ]);
        }
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      getstaffpromise();
      getmeetingroom();
      gethotdesking();
      getlocker();
      getvisitor();
      getemployeeattendance();
      getemployeeattendancegraph();

      sockets.off('staffpromisetoday').on('staffpromisetoday', async (data) => {
        if (data) {
          console.log('reload staff promise dashboard');
          getstaffpromise();
          getemployeeattendance();
          getemployeeattendancegraph();
        }
      });
      sockets.off('adminroomstatus').on('adminroomstatus', async (data) => {
        if (data) {
          console.log('reload meeting room dashboard');
          getmeetingroom();
        }
      });
      sockets.off('adminhotdesking').on('adminhotdesking', async (data) => {
        if (data) {
          console.log('reload hotdesking dashboard');
          gethotdesking();
        }
      });
      sockets.off('lockeravailable').on('lockeravailable', async (data) => {
        if (data) {
          getlocker();
          console.log('reload locker employee dashboard');
        }
      });
      sockets
        .off('livecapturevisitorinsert')
        .on('livecapturevisitorinsert', async (data) => {
          if (data) {
            getvisitor();
            console.log('reload visitor dashboard');
          }
        });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <>
      <div className='app-header'>
        <div className='dashboard_title'>
          <h1>Dashboard</h1>
        </div>
      </div>
      <div className='management-flex'>
        <div className='dashboardinner'>
          <div className='dashboardcontainer'>
            <div className='staffpromise  dashboard_boxshadow'>
              <p>Staff on office premises today</p>
              <div className='border-container'>
                <div className='legacy-bar'>
                  <div className='legacy-bar-mask'></div>
                  <div
                    className='legacy-bar-progress'
                    style={{
                      right: `${
                        100 - (staff.present / staff.totalstaff) * 100 ?? 100
                      }%`,
                    }}
                  ></div>
                </div>
                <h4>{staff.present}</h4>
              </div>

              <h5 className='gray-color'>
                Max office capacity {staff.totalstaff}
              </h5>
            </div>
            {/* <div className='datetime dashboard_boxshadow'>
              <p>Day: {moment().format('dddd')}</p>
              <p>Date: {moment().format('DD-MM-YYYY')}</p>
            </div> */}
          </div>
          <div className='dashboardcontainer'>
            <div className='meetingroom dashboard_boxshadow dashnboardbookingcard'>
              <p>Meeting room</p>
              <div className='circle'>
                <Piechart1
                  color={['rgba(80, 181, 193, 1)', 'rgba(255, 191, 20, 1)']}
                  data={[
                    { value: meetingroom.Available, name: 'Available' },
                    { value: meetingroom.Occupied, name: 'Occupied' },
                  ]}
                />
              </div>
              <div className='sectiontext'>
                <div className='containerstatus'>
                  <p>{meetingroom.Available}</p>
                  <p>Available</p>
                </div>
                <div className='containerstatus'>
                  <p>{meetingroom.Occupied}</p>
                  <p>Occupied</p>
                </div>
              </div>
            </div>
            <div className='hotdesking dashboard_boxshadow dashnboardbookingcard'>
              <p>Hot desk</p>
              <div className='circle'>
                <Piechart1
                  color={['rgba(80, 181, 193, 1)', 'rgba(255, 191, 20, 1)']}
                  data={[
                    { value: hotdesking.Available, name: 'Available' },
                    { value: hotdesking.Occupied, name: 'Occupied' },
                  ]}
                />
              </div>
              <div className='sectiontext'>
                <div className='containerstatus'>
                  <p>{hotdesking.Available}</p>
                  <p>Available</p>
                </div>
                <div className='containerstatus'>
                  <p>{hotdesking.Occupied}</p>
                  <p>Occupied</p>
                </div>
              </div>
            </div>
            <div className='locker dashboard_boxshadow dashnboardbookingcard'>
              <p>Locker</p>
              <div className='circle'>
                <Piechart1
                  color={['rgba(80, 181, 193, 1)', 'rgba(255, 191, 20, 1)']}
                  data={[
                    { value: locker.Available, name: 'Available' },
                    { value: locker.Occupied, name: 'Occupied' },
                  ]}
                />
              </div>
              <div className='sectiontext'>
                <div className='containerstatus'>
                  <p>{locker.Available}</p>
                  <p>Available</p>
                </div>
                <div className='containerstatus'>
                  <p>{locker.Occupied}</p>
                  <p>Occupied</p>
                </div>
              </div>
            </div>
            <div className='visitor dashboard_boxshadow dashnboardbookingcard'>
              <p>Visitor</p>
              <div className='circle'>
                <Piechart1
                  color={['rgba(80, 181, 193, 1)', 'rgba(255, 191, 20, 1)']}
                  data={[
                    { value: visitor.in, name: 'IN' },
                    { value: visitor.out, name: 'OUT' },
                  ]}
                />
              </div>
              <div className='sectiontext'>
                <div className='containerstatus'>
                  <p>{visitor.in}</p>
                  <p>IN</p>
                </div>
                <div className='containerstatus'>
                  <p>{visitor.out}</p>
                  <p>OUT</p>
                </div>
              </div>
            </div>
          </div>
          <div className='dashboardcontainer'>
            <div className='graphdashboard'>
              <Chart
                type='line'
                width='100%'
                height='100%'
                series={product}
                options={option}
              ></Chart>
            </div>
            <div className='dashnboardbookingcard dashboard_boxshadow card4'>
              <p>Employee Attandence</p>
              <div className='circle'>
                <Piechartper
                  color={['rgba(80, 181, 193, 1)', 'rgba(255, 191, 20, 1)']}
                  data={[
                    { value: employeeattendance.present, name: 'Present' },
                    { value: employeeattendance.absent, name: 'Absent' },
                  ]}
                />
                <div className='colorsection'>
                  <div className='color1'>
                    <div className='presentcolor'></div>
                    <p>Present</p>
                  </div>
                  <div className='color1'>
                    <div className='absentcolor'></div>
                    <p>Absent</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
