import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hotdesking from 'api/hotdesking';
import '../Styles/Floor.css';
import '../Styles/Desk.css';
import InputWithLable from '../components/input/InputWithLable';
import axios from 'axios';
import config from '../config/token';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';

import { Toast } from '../components/Swal/Swal';
import { useLocation } from 'react-router-dom';
function Desk() {
  const [showRoom1, setshowRoom1] = useState(false);
  const [FloorData, setFloorData] = useState([]);
  const [FloorDataList, setFloorDataList] = useState([]);
  const [filterZone, setfilterZone] = useState('');
  const location = useLocation();
  const [AddFloorName, setAddFloorName] = useState('');
  const [UpdateFloorData, setUpdateFloorData] = useState('');

  useEffect(() => {
    getFloor();
    getDeskAll();
    if (location.pathname === '/desk') {
      document.getElementById('Desk').style.backgroundColor = '#394A7A';
    }
  }, [filterZone]);

  const getFloor = async () => {
    await Hotdesking.getfloor()
      .then(function (response) {
        setFloorData(response?.data?.data?.floor ?? []);
        // setFloorDataList(response.data?.data?.floor)
      })
      .catch(function (error) {
        console.log(error.response.statusText);
      });
  };

  const getDeskAll = async () => {
    var obj = {
      companies_id: localStorage.getItem('companies_id'),
      floor_id: filterZone,
    };
    await axios
      .post(
        'https://api.chopeai.com/api/hotdeskingAdmin/hotdeskingfetchall',
        obj,
        config
      )
      .then(function (response) {
        console.log(response.data?.data?.hotdesking_floor);
        // setFloorData(response.data?.data?.floor)
        setFloorDataList(response.data?.data?.hotdesking_floor);
      })
      .catch(function (error) {
        console.log(error.response.statusText);
      });
  };

  const UpdateFloor = async (d) => {
    setshowRoom1(true);
    console.log(d);
    setAddFloorName(d.floor_name);
    setUpdateFloorData(d);
  };

  const deleteFloor = async (d) => {
    var obj = {
      companies_id: d.companies_id,
      floor_id: d._id,
    };

    console.log(obj);
    await axios
      .post(
        'https://api.chopeai.com/api/hotdeskingAdmin/deletefloor',
        obj,
        config
      )
      .then(function (response) {
        console.log(response.data?.data);
        Toast.fire({
          icon: 'success',
          title: response.data?.data.message,
        });
        // getFloor()
        getFloor();
        // getData()
      })
      .catch(function (error) {
        console.log(error.response.statusText);
        Toast.fire({
          icon: 'error',
          title: error.response.data?.data,
        });
        if (error.response.statusText === 'Unauthorized') {
          // getToken()
        }
      });
  };

  const addFloor = () => {
    setAddFloorName('');
    setshowRoom1(true);
    setUpdateFloorData('');
  };

  const AddFloor = async () => {
    if (
      AddFloorName === '' ||
      AddFloorName === null ||
      AddFloorName === undefined
    ) {
    } else {
      var obj = {
        companies_id: localStorage.getItem('companies_id'),
        floor_name: AddFloorName,
      };
      console.log(obj);

      await axios
        .post(
          'https://api.chopeai.com/api/hotdeskingAdmin/addfloor',
          obj,
          config
        )
        .then(function (response) {
          console.log(response.data?.data);
          Toast.fire({
            icon: 'success',
            title: response.data?.data.message,
          });
          getFloor();
          setshowRoom1(false);
        })
        .catch(function (error) {
          console.log(error.response.statusText);
          Toast.fire({
            icon: 'error',
            title: error.response.data?.data.message,
          });
        });
    }
  };

  const updateFloor = async () => {
    console.log(UpdateFloorData);
    if (
      AddFloorName === '' ||
      AddFloorName === null ||
      AddFloorName === undefined
    ) {
    } else {
      var obj = {
        companies_id: localStorage.getItem('companies_id'),
        floor_id: UpdateFloorData._id,
        floor_name: AddFloorName,
      };
      console.log(obj);

      await axios
        .post(
          'https://api.chopeai.com/api/hotdeskingAdmin/editfloor',
          obj,
          config
        )
        .then(function (response) {
          console.log(response.data?.data);
          Toast.fire({
            icon: 'success',
            title: response.data?.data.message,
          });

          // getData()
          getFloor();
          console.log(filterZone);
          // filterFloor()
          // getZone()
          setshowRoom1(false);
        })
        .catch(function (error) {
          console.log(error.response.statusText);
          Toast.fire({
            icon: 'error',
            title: error.response.data?.data.message,
          });
        });
    }
  };

  const UpdateZone = async (d) => {
    // setshowRoom1(true)
    console.log(d);
    console.log(d.zone_name);
    // setAddFloorName(d.floor_name)
    // setUpdateFloorData(d)
  };

  const deleteZone = async (d) => {
    console.log(d);
    var obj = {
      companies_id: d.zonetable.companies_id,
      zone_id: d._id,
    };

    console.log(obj);
    // await  axios.post('https://api.chopeai.com/api/hotdeskingAdmin/deletefloor',obj,config)
    // .then(function (response) {
    //   console.log(response.data?.data);
    //   Toast.fire({
    //     icon: 'success',
    //     title: response.data?.data.message,
    //   })
    //   // getFloor()
    //   getFloor()
    //   // getData()

    // })
    // .catch(function (error) {
    //   console.log(error.response.statusText);
    //   Toast.fire({
    //     icon: 'error',
    //     title: error.response.data?.data,
    //   })
    //   if(error.response.statusText === 'Unauthorized'){
    //     // getToken()
    //   }
    // });
  };

  return (
    <>
      <div className='appHeader'>
        <p>Facility Master</p>
      </div>
      <div className='management-flex'>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/master-facility'>
              <button
                id='MeetingRoom'
                className='add-new report-btn-item blue-btn-box active'
              >
                Meeting Room
              </button>
            </Link>
            <Link to='/locker'>
              <button
                id='Locker'
                className='add-new report-btn-item blue-btn-box active'
              >
                Locker
              </button>
            </Link>
            <Link to='/panel'>
              <button
                id='Panel'
                className='add-new report-btn-item blue-btn-box active'
              >
                Door Panel
              </button>
            </Link>
            <Link to='/desk'>
              <button
                id='Desk'
                className='add-new report-btn-item blue-btn-box active'
              >
                Hot Desking
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='manament-table-header'>
          <div className='btn-divder'>
            <button className='add-new-btn' onClick={addFloor}>
              Add Floor
            </button>
            <div className='input-container'>
              <select
                name='cars'
                id='floor'
                className='input-field simple-select'
                onChange={(e) => setfilterZone(e.target.value)}
                value={filterZone}
              >
                {FloorData?.map((d, i) => {
                  return (
                    <option key={i} value={d._id}>
                      {d.floor_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className='table-container '>
          <div className='floor-list'>
            {FloorDataList.length >= 0 ? (
              FloorDataList.map((d, i) => {
                return (
                  <>
                    <div
                      key={i}
                      className={
                        d.status === 'Available'
                          ? 'Available'
                          : d.status === 'Occupied'
                          ? 'Occupied'
                          : null
                      }
                    >
                      <span>
                        <div>
                          <AiOutlineDelete onClick={() => deleteFloor(d)} />

                          <BiEditAlt onClick={() => UpdateFloor(d)} />
                        </div>
                      </span>

                      <p className='floor-title'>{d.floor_name}</p>
                      <div>
                        {d?.zone_detail.length > 0
                          ? d?.zone_detail.map((z) => {
                              return (
                                <div className='desk-wrap'>
                                  <p>{z.zonetable?.zone_name}</p>
                                  <div className='zone-btns'>
                                    <AiOutlineDelete
                                      onClick={() => deleteZone(z)}
                                    />

                                    <BiEditAlt onClick={() => UpdateZone(z)} />
                                  </div>
                                </div>
                              );
                            })
                          : 'No Zone Found'}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>No Floor Found</>
            )}
          </div>
          {/* <div className='zone-list' >
            {ZoneList.length>0?ZoneList.map((d,i)=>{
                              return  <>

                             
                              <div key={i} className={d.status === 'Available'?'Available':d.status === "Occupied"?"Occupied":null} >
                                
                              <span>
                                  <div>
                                  <AiOutlineDelete onClick={()=>deleteFloor(d)}/>
                                  
                                  <BiEditAlt onClick={()=>UpdateFloor(d)}/>
                                  </div>

                              </span>
                             
                            <p className="floor-title">{d.zone_name}</p>
                            <div>
                            
                            </div>
                          
                            </div>
                            </>
                            }):
                            
                            <>
                            No Zone Found
                            </>}

                            
                
            </div> */}
        </div>
      </div>

      {showRoom1 && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Add Floor</h3>
              <div className='addfloor'>
                <InputWithLable
                  placeholder='Floor Name '
                  type='text'
                  callback={(event) => setAddFloorName(event.target.value)}
                  value={AddFloorName}
                />

                <div className='btns'>
                  <button
                    className='create-btn cancel-btn'
                    onClick={() => setshowRoom1(false)}
                  >
                    Cancel
                  </button>
                  {UpdateFloorData ? (
                    <button className='create-btn' onClick={updateFloor}>
                      Update
                    </button>
                  ) : (
                    <button className='create-btn' onClick={AddFloor}>
                      Add Floor
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Desk;
