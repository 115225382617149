import api from './auth';
var obj = {
  companies_id: localStorage.getItem('companies_id'),
};
class BookingApi {
  getfloor() {
    return api.post('/room/floor', obj);
  }
  getbooking() {
    return api.post('/room/getbooking', obj);
  }
  getemail() {
    return api.post('/admin/getemail', obj);
  }

  getavailable(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/available', datafull);
  }
  meetingroombooking(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/booking', datafull);
  }

  meetingcancel(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/cancellation', datafull);
  }
}

export default new BookingApi();
