import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import SideBar from '../components/sideBar/SideBar';

function ProtectedRoute() {
  const auth = JSON.parse(localStorage.getItem('chopeaitoken'));
  return auth ? (
    <>
      {' '}
      <div style={{ display: 'flex' }}>
        <SideBar />
        <div
          style={{ flexGrow: 1, minHeight: '100vh' }}
          className='content-container'
        >
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to='/login' />
  );
}

export default ProtectedRoute;
