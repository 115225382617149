// import '../Styles/Invoices.css'
import '../Styles/global.css';
import '../Styles/Companies.css';
import React, { useMemo, useState, useEffect } from 'react';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import { Toast, popUp } from '../components/Swal/Swal';
import { Visitorlist } from '../helper/columns';
// import { BsEye } from 'react-icons/bs';
import { BiDetail } from 'react-icons/bi';
import { ColumnFilter } from '../components/table_filter/ColumnFilter';
// import { useNavigate } from "react-router-dom";
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import InputWithLable from '../components/input/InputWithLable';
// import SimpleDropDown from '../components/simpleDropdown/SimpleDropDown'

import { Link } from 'react-router-dom';
import usermanagement from 'api/usermanagement';
import visitormanagement from 'api/visitor';
import { sockets } from 'apisocket/socket';

// import { AiFillCaretDown} from 'react-icons/ai'
function VisterManagement() {
  // const [showRoom, setshowRoom] = useState(false)
  const [showCompany, setshowCompany] = useState(false);
  const [CompanyData, setCompanyData] = useState([]);
  const [RFID, setRFID] = useState('');

  const [EmployeeRole, setEmployeeRole] = useState([]);
  const [Gender, setGender] = useState([]);
  // const [facility ,setfacility] = useState([])
  // const [CompanyId, setCompanyId] = useState('')
  // const [Data ,setData] = useState([])
  // const [Data1 ,setData1] = useState([])
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Designation, setDesignation] = useState('');
  const [DesignationData, setDesignationData] = useState([]);
  const [PhoneNo, setPhoneNo] = useState('');
  const [Department, setDepartment] = useState('');
  const [DepartmentData, setDepartmentData] = useState([]);
  // const [LoginNo, setLoginNo] = useState('')
  const [start_date, SetStart_date] = useState('');
  const [end_date, SetEnd_date] = useState('');

  const [Id, setId] = useState('');
  const [image, setimage] = useState(null);
  const columns = useMemo(() => Visitorlist, []);
  const data = useMemo(() => CompanyData, [CompanyData]);
  // const history = useNavigate()
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;

  const EditRoom = (d) => {
    console.log(d);
    setimage(d.employee_image);
    setEmail(d.email);
    setName(d.name);
    setPhoneNo(d.phone_no);
    setGender(d.gender);
    setEmployeeRole(d.role);
    setDesignation(d.designation);
    setDepartment(d.department);
    setId(d._id);
    setshowCompany(true);
  };

  const cancel = () => {
    setId('');
    setimage('');
    setEmail('');
    setName('');
    setPhoneNo('');
    setGender('');
    setEmployeeRole('');
    setDesignation('');
    setDepartment('');
    setshowCompany(false);
  };

  const AddNewCompany = () => {
    setshowCompany(true);
    console.log('add new company');
    setId('');
    setimage('');
    setEmail('');
    setName('');
    setPhoneNo('');
    setGender('');
    setEmployeeRole('');
    setDesignation('');
    setDepartment('');
  };

  const getData1 = async () => {
    await usermanagement
      .getFacility()
      .then(function (response) {
        response.data?.data?.facility?.filter((d) => {
          return d.facility_name === 'meeting room';
        });
        // setfacility(data[0].authentication)
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  const getData = async () => {
    await visitormanagement
      .visitornotapprovallist()
      .then(function (response) {
        console.log(response.data?.data?.visitor);
        setCompanyData(response.data?.data?.visitor ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getDesignation = async () => {
    await usermanagement
      .getDesignation()
      .then(function (response) {
        console.log(response.data?.data?.data);
        setDesignationData(response.data?.data?.employee_role ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  const getDepartment = async () => {
    await usermanagement
      .getDepartment()
      .then(function (response) {
        console.log(response.data?.data?.data);
        setDepartmentData(response.data?.data?.employee_role ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  const addvisitor = async () => {
    var obj = {
      companies_id: localStorage.getItem('companies_id'),

      rfid: RFID,
      start_date: start_date,
      end_date: end_date,
      email: Email,
      name: Name,
      gender: Gender,
      designation: Designation,
      department: Department,
      phone_no: PhoneNo,
      facility_access: [
        {
          facility_id: '63624c4d3d4397d46081666e',
          facility_name: 'meeting room',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
        {
          facility_id: '63624c82573e35792966859b',
          facility_name: 'locker',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
        {
          facility_id: '638f8876eb8dc2581c31daa9',
          facility_name: 'main door',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
        {
          facility_id: '63624c7b573e357929668597',
          facility_name: 'hot desking',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
      ],
    };
    var e = document.getElementById('userProfile');

    var bodyFormData = new FormData();
    bodyFormData.append('data', JSON.stringify(obj));
    bodyFormData.append('image', e.files[0]);

    console.log(bodyFormData);
    await visitormanagement
      .createvisitor(bodyFormData)
      .then(function (response) {
        getData();
        setshowCompany(false);
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  const Updatevisitor = async () => {
    var obj = {
      companies_id: localStorage.getItem('companies_id'),
      rfid: RFID,
      visitor_id: Id,
      email: Email,
      name: Name,
      start_date: start_date,
      end_date: end_date,
      gender: Gender,
      designation: Designation,
      department: Department,
      phone_no: PhoneNo,
      facility_access: [
        {
          facility_id: '63624c4d3d4397d46081666e',
          facility_name: 'meeting room',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
        {
          facility_id: '63624c82573e35792966859b',
          facility_name: 'locker',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
        {
          facility_id: '638f8876eb8dc2581c31daa9',
          facility_name: 'main door',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
        {
          facility_id: '63624c7b573e357929668597',
          facility_name: 'hot desking',
          authentication: [
            {
              auth_id: '6362a43c03a71f9735fded34',
              auth_name: 'passcode',
            },
            {
              auth_id: '6362a43303a71f9735fded30',
              auth_name: 'RFID',
            },
            {
              auth_id: '638f88baeb8dc2581c31dab2',
              auth_name: 'QRscan',
            },
            {
              auth_id: '6362a44403a71f9735fded38',
              auth_name: 'face authentication',
            },
          ],
        },
      ],
    };
    var e = document.getElementById('userProfile');

    var bodyFormData = new FormData();
    bodyFormData.append('image', e.files[0]);
    bodyFormData.append('data', JSON.stringify(obj));
    await visitormanagement
      .updatevisitor(bodyFormData)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        // if(response.data?.data?.message === "successfully created companies"){
        getData();
        setshowCompany(false);
        // }
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  const deletevisitor = async (d) => {
    var obj = {
      visitor_id: d._id,
    };
    await visitormanagement
      .deletevisitor(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  const visitorapproval = async (d) => {
    console.log(d);
    var obj = {
      visitor_id: d._id,
    };
    await visitormanagement
      .visitorapproval(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  useEffect(() => {
    if (window.location.pathname === '/vister-management') {
      document.getElementById('Visitor').style.backgroundColor = '#394A7A';
    }
    getData();
    getDepartment();
    getDesignation();

    getData1();
    sockets
      .off('visitortrainedimage')
      .on('visitortrainedimage', async (data) => {
        if (data) {
          console.log('reload visitor information');
          getData();
        }
      });
  }, []);
  return (
    <>
      <div className='appHeader'>
        <p>Visitor Management</p>
      </div>
      <div className='management-flex'>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/vister-management'>
              <button
                id='Visitor'
                className='add-new report-btn-item blue-btn-box active'
              >
                Non Approved
              </button>
            </Link>
            <Link to='/visitor-approval'>
              <button
                id='Visitorapproval'
                className='add-new report-btn-item blue-btn-box active'
              >
                Approved
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='table-container border-radius5px box-shadow'>
        <div className='manament-table-header'>
          <span>Visitor Management</span>
          <div className='btn-divder'>
            {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
            <button className='add-new-btn' onClick={AddNewCompany}>
              Add New Visitor
            </button>
          </div>
        </div>
        {/* divder */}
        <div className='justify-center'>
          <span className='divder'></span>
        </div>

        <div className='table-container'>
          <>
            <table {...getTableProps()} id='test'>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                        <span
                          className='table-asc-dec'
                          onClick={() => column.toggleSortBy()}
                        >
                          <span>{column.render('Header')}</span>
                          <span>
                            {' '}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </span>
                      </th>
                    ))}
                    <th>
                      <div className='margintop2rem'>Action</div>
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      <td className='action-table-btns'>
                        <BiEdit
                          {...row.getRowProps()}
                          onClick={() => EditRoom(row.original)}
                          className='cursor-pointer'
                        />

                        <AiOutlineDelete
                          onClick={() => deletevisitor(row.original)}
                          className='cursor-pointer'
                        />
                        <BiDetail
                          onClick={() => visitorapproval(row.original)}
                          className='cursor-pointer'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='pagination-section'>
              <div className='pagination-page-section'>
                <div>
                  <span>
                    Page
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
                <span>
                  | Go to page:{' '}
                  <input
                    type='number'
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: '50px' }}
                  />
                </span>{' '}
              </div>

              <div className='pagination-btn-section'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <FiChevronsLeft />
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <FiChevronLeft /> <span>Previous</span>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <span>Next</span> <FiChevronRight />
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <FiChevronsRight />
                </button>
              </div>

              <div className='pagination-select-section'>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 15, 20, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        </div>
      </div>
      {showCompany && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Visitor Details</h3>
              <div className='company-detail-list'>
                <InputWithLable
                  placeholder='Name'
                  callback={(event) => setName(event.target.value)}
                  value={Name}
                />
                <InputWithLable
                  placeholder='Email '
                  callback={(event) => setEmail(event.target.value)}
                  value={Email}
                />
                {/* <InputWithLable placeholder='Billing Address:' callback={(event)=>setBillingAddress(event.target.value)} value={BillingAddress}/> */}
                <InputWithLable
                  placeholder='Phone:'
                  callback={(event) => setPhoneNo(event.target.value)}
                  value={PhoneNo}
                />
                <InputWithLable
                  placeholder='RFID:'
                  callback={(event) => setRFID(event.target.value)}
                  value={RFID}
                />
                <InputWithLable
                  placeholder='StartDate:'
                  callback={(event) => SetStart_date(event.target.value)}
                  value={start_date}
                />
                <InputWithLable
                  placeholder='EndDate:'
                  callback={(event) => SetEnd_date(event.target.value)}
                  value={end_date}
                />
                {/* <InputWithLable placeholder='Company Email: ' callback={(event)=>setEmail(event.target.value)} value={Email}/> */}
                {/* <InputWithLable placeholder='Contact Person Name: *' callback={(event)=>setContactPersonName(event.target.value)} value={ContactPersonName}/> */}
                <div className='input-withlable-container '>
                  <span>Gender</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setGender(e.target.value)}
                      value={Gender}
                    >
                      <option value='' disabled selected>
                        Gender
                      </option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                    </select>
                  </div>
                </div>

                <div className='input-withlable-container '>
                  <span>Designation</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setDesignation(e.target.value)}
                      value={Designation}
                    >
                      <option value='' disabled selected>
                        Designation
                      </option>

                      {DesignationData.map((d, i) => {
                        return (
                          <option key={i} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className='input-withlable-container '>
                  <span>Department</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setDepartment(e.target.value)}
                      value={Department}
                    >
                      <option value='' disabled selected>
                        Department
                      </option>

                      {DepartmentData.map((d, i) => {
                        return (
                          <option key={i} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className='facility-section'>
                  <span className='header'>Facility Access</span>
                  <div className='flex-column-with-row'>
                    <div>
                      {/* {Data.map((d,i)=>{
                return <><div className='align-center'>
                                <input type="checkbox"  id={d._id} name="facility" value={d.facility} onChange={(e)=>console.log(e)}/>
                                <label htmlFor="vehicle3" className='upperCase'>{d.facility}</label>
                  </div>
                  </>
                })

                } */}
                    </div>

                    <div className='sectionn'>
                      <div className='input-container'>
                        <select
                          name='cars'
                          id='cars'
                          className='input-field simple-select'
                          onChange={(e) => setEmployeeRole(e.target.value)}
                          value={EmployeeRole}
                        >
                          <option value='' disabled selected>
                            Authentication
                          </option>

                          {/* { Data.map((d,i)=>{
            return  <option key={i} value={d.facility} className='upperCase'>{d.facility}</option>
            })
            } */}
                        </select>
                      </div>

                      {/* {Data1.map((d,i)=>{
                return <><div className='align-center'>
                                <input type="checkbox"  id={d._id} name="facility" value={d.name} onChange={(e)=>console.log(e)}/>
                                <label htmlFor="vehicle3" className='upperCase'>{d.name}</label>
                  </div>
                  </>
                })

                } */}
                    </div>
                  </div>
                </div>
                <div className='facility-section'>
                  <span className='header'>Employee Image</span>
                  <div className='flex-column-with-row'>
                    <input
                      className='file-selection'
                      id='userProfile'
                      type='file'
                    />
                    {image && (
                      <div className='user-profile-wrapper'>
                        <img src={image} alt='user-Profile-img'></img>
                      </div>
                    )}
                  </div>
                </div>
                <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>
                    Cancel
                  </button>
                  {!Id ? (
                    <button className='create-btn' onClick={addvisitor}>
                      Create
                    </button>
                  ) : (
                    <button className='create-btn' onClick={Updatevisitor}>
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VisterManagement;
