import React, { useEffect, useState, useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import './DropDownSelect.css';
function DropDownSelect({ limit, callback, data, remove }) {
  const [no, setlimit] = useState(null);
  const multiselectRef = useRef();
  console.log(limit);
  useEffect(() => {
    setlimit(limit);
    console.log(no);
  }, [limit, no]);
  useEffect(() => {
    multiselectRef.current.resetSelectedValues();
  }, [limit]);

  //   const handleselect = (s,r)=>{
  //     setd(s)
  //     callback(d)
  // console.log(s,r);
  //   }
  return (
    <div style={{ width: '60%' }}>
      <Multiselect
        displayValue='email'
        onaddressPressFn={function noRefCheck() {}}
        onRemove={(s, r) => remove(s, r)}
        onSearch={function noRefCheck() {}}
        // onSelect={function noRefCheck(){}}
        onSelect={(s, r) => callback(s, r)}
        options={data && data}
        selectionLimit={no}
        ref={multiselectRef}
        placeholder='Select Invitees'
      />
    </div>
  );
}

export default DropDownSelect;
