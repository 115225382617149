import '../Styles/global.css';
import '../Styles/Companies.css';
import '../Styles/LiveCapture.css';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { BiDetail } from 'react-icons/bi';
import InputWithLable from '../components/input/InputWithLable';

import { Toast, popUp } from '../components/Swal/Swal';
import { sockets } from 'apisocket/socket';
import { ColumnFilter } from '../components/table_filter/ColumnFilter';
import { employeeattendance } from '../helper/columns';
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import employeeattendanceapi from 'api/employeeattendance';
function LiveCapture() {
  const renderAfterCalled = useRef(false);
  const [Data, setData] = useState([]);
  const [showRoom, setshowRoom] = useState(false);

  const columns = useMemo(() => employeeattendance, []);

  const data = useMemo(() => Data, [Data]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const getData = async () => {
    await employeeattendanceapi
      .today_attendance()
      .then(function (response) {
        console.log(response.data?.data?.livecapture ?? []);
        setData(response.data?.data?.livecapture ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };
  const cancel = () => {
    setshowRoom(false);
  };
  const { pageIndex, pageSize } = state;
  const EditRoom = async (d) => {
    setshowRoom(true);
    console.log(d);
  };
  useEffect(() => {
    if (window.location.pathname === '/live-capture') {
      document.getElementById('LiveCapture').style.backgroundColor = '#394A7A';
    }
    if (!renderAfterCalled.current) {
      sockets.off('staffpromisetoday').on('staffpromisetoday', async (data) => {
        if (data) {
          console.log('reload attendances live');
          getData();
        }
      });
      getData();
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <>
      <div className='appHeader'>
        <p>Live Capture</p>
      </div>
      <div className='management-flex'>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/live-capture'>
              <button
                id='LiveCapture'
                className='add-new report-btn-item blue-btn-box active'
              >
                Today Attendance
              </button>
            </Link>
            <Link to='/full-attendance'>
              <button
                id='Full-Attendance'
                className='add-new report-btn-item blue-btn-box active'
              >
                Full Record Attendances
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='table-container border-radius5px box-shadow'>
        <div className='manament-table-header'>
          <span>Employee Attendance</span>
          <div className='btn-divder'>
            {/* <button className='export-btn usermangement-room-book-btn' onClick={()=>exportPDF('test')}><AiOutlineCalendar/> Export </button> */}
          </div>
        </div>
        {/* divder */}
        <div className='justify-center'>
          <span className='divder'></span>
        </div>

        <div className='table-container'>
          <>
            <table {...getTableProps()} id='test'>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                        <span
                          className='table-asc-dec'
                          onClick={() => column.toggleSortBy()}
                        >
                          <span>{column.render('Header')}</span>
                          <span>
                            {' '}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </span>
                      </th>
                    ))}
                    <th>
                      <div className='margintop2rem'>Action</div>
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      <td className='action-table-btns1'>
                        <BiDetail
                          {...row.getRowProps()}
                          onClick={() => EditRoom(row.original)}
                          className='cursor-pointer'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='pagination-section'>
              <div className='pagination-page-section'>
                <div>
                  <span>
                    Page
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
                <span>
                  | Go to page:{' '}
                  <input
                    type='number'
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: '50px' }}
                  />
                </span>{' '}
              </div>

              <div className='pagination-btn-section'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <FiChevronsLeft />
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <FiChevronLeft /> <span>Previous</span>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <span>Next</span> <FiChevronRight />
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <FiChevronsRight />
                </button>
              </div>

              <div className='pagination-select-section'>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 15, 20, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        </div>
      </div>
      {showRoom && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Edit Room</h3>
              <div className='company-detail-list'>
                {/* <InputWithLable
                  placeholder='Meeting Room Name'
                  callback={(event) => setRoomName(event.target.value)}
                  value={RoomName}
                /> */}
                {/* <InputWithLable placeholder='Floor: ' callback={(event)=>setFloorName(event.target.value)} value={FloorName}/> */}
                <div className='input-withlable-container '>
                  <span>Floor</span>
                </div>

                <div className='facility-section'>
                  <span className='header'>Authentication</span>
                  <div className='flex-column-with-row'></div>
                </div>
                <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LiveCapture;
