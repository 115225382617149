import api from 'api/auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class Dashboard {
  staffpromise() {
    return api.post('/admin/dashboard/staffpromise', obj);
  }
  meetingroom() {
    return api.post('/admin/dashboard/meetingavailable', obj);
  }
  hotdesking() {
    return api.post('/admin/dashboard/hotdeskingavailable', obj);
  }
  locker() {
    return api.post('/admin/dashboard/lockeravailable', obj);
  }
  visitor() {
    return api.post('/admin/dashboard/visitorcount', obj);
  }
  getemployeeattedance() {
    return api.post('/admin/dashboard/employeeattendance', obj);
  }

  getemployeeattedancegraph() {
    return api.post('/admin/dashboard/employeeattendancegraph', obj);
  }
}

export default new Dashboard();
