import React, { useState, useMemo, forwardRef, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../Styles/global.css';
import '../Styles/BookingManagement.css';
import { AiOutlineCalendar } from 'react-icons/ai';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { IoTimerOutline } from 'react-icons/io5';
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import hotdeskingapi from 'api/hotdesking';
// import MOCK_DATA from '../helper/BookingManagement.json'
import { Desk } from '../helper/columns';
import { GlobalFilter } from '../components/table_filter/GlobalFilter';
import { ColumnFilter } from '../components/table_filter/ColumnFilter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Toast, popUp } from '../components/Swal/Swal';
import { MdOutlineDoDisturbAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { sockets } from 'apisocket/socket';
function DeskManagement() {
  const renderAfterCalled = useRef(false);

  const [showAvailable, setshowAvailable] = useState(false);
  const [roomTableData, setRoomTableData] = useState([]);
  const [availableRoomData, setavailableRoomData] = useState([]);
  const [FloorName, setFloorName] = useState('');
  // const [departmentData ,setdepartmentData] = useState([])
  const [value, setvalue] = useState(new Date());
  const columns = useMemo(() => Desk, []);
  const data = useMemo(() => roomTableData, [roomTableData]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [userdata, setuserdata] = useState(null);
  const [Floor, setFloor] = useState([]);
  // const [bdate, setBdate] = useState();
  // const [stime, setStime] = useState();

  const [ZoneName, setZoneName] = useState('');
  const [Seat, setSeat] = useState('');
  const [Emp, setEmp] = useState('');

  const [Zone, setZone] = useState([]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const changeDate = (e) => {
    var date = moment(e).format('DD/MM/YYYY');
    console.log(date);
    setGlobalFilter(date);
    setvalue(e);
  };

  // const selectdate = ()=>{

  // }

  //
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;
  const { globalFilter } = state;
  const BookRoom = async () => {
    const obj = {
      start_date: moment(startDate).format('DD-MM-YYYY'),
      end_date: moment(endDate).format('DD-MM-YYYY'),
      start_time: moment(startTime).format('HH:mm'),
      end_time: moment(endTime).format('HH:mm'),
      floor_id: FloorName,
      zone_id: ZoneName,
    };
    await hotdeskingapi
      .getavailable(obj)
      .then(function (response) {
        console.log(response.data?.data?.availablehotdeskings.table);
        // if(response.data?.data?.availablehotdeskings.length > 0 ){
        setshowAvailable(true);
        setavailableRoomData(response.data?.data?.availablehotdeskings.table);
      })
      .catch((error) => {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });

    // setshowRoom(true)
  };

  const cancel1 = () => {
    setshowAvailable(false);
  };
  const detData = async () => {
    await hotdeskingapi
      .getemail()
      .then(function (response) {
        setuserdata(response?.data?.data?.data ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  const getFoolr = async () => {
    await hotdeskingapi
      .getfloor()
      .then(function (response) {
        setFloor(response?.data?.data?.floor ?? []);
      })
      .catch(function (error) {
        console.log(error.response.statusText);
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  const getZone = async (d) => {
    var obj = {
      floor_id: d,
    };
    await hotdeskingapi
      .getzone(obj)
      .then(function (response) {
        setZone(response?.data?.data?.zones ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  // useEffect(() => {
  //   // setuserdata(null)
  // }, [no])
  const getdata = async () => {
    await hotdeskingapi
      .getbooking()
      .then((res) => {
        setRoomTableData(res?.data?.data?.hotdesking ?? []);
      })
      .catch((error) => {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const StartTimeGet = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const EndTimeGet = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  //

  const CancelBook = async (d) => {
    console.log(d);
    var obj = {
      booking_id: d.id,
    };
    await hotdeskingapi
      .seatbookingcancel(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getdata();
      })
      .catch(function (error) {
        console.log(error.response.statusText);
      });
  };

  const test = (e) => {
    setFloorName(e.target.value);
    getZone(e.target.value);
  };

  const BookDesk = async (d) => {
    const desc = prompt('Enter Description');
    console.log(d);
    var obj = {
      floor_id: FloorName,
      zone_id: ZoneName,
      table_id: d.table_id,
      seat_id: Seat,
      start_date: moment(startDate).format('DD-MM-YYYY'),
      end_date: moment(endDate).format('DD-MM-YYYY'),
      start_time: moment(startTime).format('HH:mm'),
      end_time: moment(endTime).format('HH:mm'),
      employee_email: Emp,
      description: desc,
    };

    if (desc) {
      await hotdeskingapi
        .seatbooking(obj)
        .then((response) => {
          Toast.fire({
            title: 'success!',
            text: response.data.data.message,
            icon: 'success',
            confirmButtonText: "Let's go",
          });
          getdata();
        })
        .catch((error) => {
          popUp.fire({
            title: error.response.data.data.message,
            icon: 'error',
          });
        });
      setshowAvailable(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/desk-management') {
      document.getElementById('desk').style.backgroundColor = '#394A7A';
    }

    if (!renderAfterCalled.current) {
      detData();
      getdata();
      getFoolr();
      sockets.off('adminhotdesking').on('adminhotdesking', async (data) => {
        if (data) {
          console.log('reload hotdesking booking');
          getdata();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <>
      <div className='app-header'>
        <h1>Booking Management</h1>
      </div>
      <div className='management-flex'>
        <div className='head-calender'>
          <Calendar onChange={changeDate} value={value} className='calender' />
        </div>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/booking-management'>
              <button
                id='MeetingRoom'
                className='add-new report-btn-item blue-btn-box active'
              >
                Meeting Room
              </button>
            </Link>
            <Link to='/desk-management'>
              <button
                id='desk'
                className='add-new report-btn-item blue-btn-box active'
              >
                Hot Desking
              </button>
            </Link>
          </div>
          <div className='management-dropdown'>
            {/* blue-btn-box */}
            <div className='input-withlable-container flex-column'>
              <p className='date-heading'>Floor</p>
              <div className='border-radius5px date-c'>
                <select
                  name='cars'
                  id='cars'
                  style={{ border: 'none', padding: '1rem' }}
                  className=' '
                  onChange={(e) => test(e)}
                  value={FloorName}
                >
                  <option value='' disabled selected>
                    Floor
                  </option>

                  {Floor.map((d, i) => {
                    return (
                      <option key={i} value={d._id}>
                        {d.floor_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {FloorName ? (
              <div className='input-withlable-container flex-column'>
                <p className='date-heading'>Zone</p>
                <div className='border-radius5px date-c'>
                  <select
                    name='cars'
                    id='cars'
                    style={{ border: 'none', padding: '1rem' }}
                    className=' '
                    onChange={(e) => setZoneName(e.target.value)}
                    value={ZoneName}
                  >
                    <option value='' disabled selected>
                      Zone
                    </option>

                    {Zone.map((d, i) => {
                      return (
                        <option key={i} value={d._id}>
                          {d.zone_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}

            {ZoneName && (
              <>
                <div className='  flex-column'>
                  <p className='date-heading'>Start Date</p>
                  <div className='border-radius5px date-c'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      customInput={<ExampleCustomInput />}
                      // timeFormat="dd/mm/yyyy"
                      dateFormat='dd/MM/yyyy'
                      minDate={moment().toDate()}
                    />
                    <AiOutlineCalendar />
                  </div>
                </div>
                <div className='flex-column'>
                  <p className='date-heading'>End Date</p>
                  <div className='border-radius5px  date-c'>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      customInput={<ExampleCustomInput />}
                      // timeFormat="dd/mm/yyyy"
                      dateFormat='dd/MM/yyyy'
                      minDate={startDate}
                    />
                    <AiOutlineCalendar />
                  </div>
                </div>
                <div className='flex-column'>
                  <p className='date-heading'>Start Time</p>
                  <div className='border-radius5px date-c'>
                    <DatePicker
                      selected={startTime}
                      onChange={(time) => setStartTime(time)}
                      customInput={<StartTimeGet />}
                      dateFormat='h:mm aa'
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      minTime={
                        new Date().getDate() ===
                        new Date(startTime ?? new Date().getTime()).getDate()
                          ? new Date()
                          : new Date().setHours(0, 0, 0)
                      }
                      maxTime={new Date().setHours(23, 59, 59)}
                      timeCaption='Start Time'
                    />
                    <IoTimerOutline />
                  </div>
                </div>
                <div className='flex-column'>
                  <p className='date-heading'>End Time</p>
                  <div className='border-radius5px date-c'>
                    <DatePicker
                      selected={endTime}
                      onChange={(time) => setEndTime(time)}
                      customInput={<EndTimeGet />}
                      dateFormat='h:mm aa'
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption='End Time'
                      minTime={
                        new Date().getDate() ===
                        new Date(endTime ?? new Date().getTime()).getDate()
                          ? new Date()
                          : new Date().setHours(0, 0, 0)
                      }
                      maxTime={new Date().setHours(23, 59, 59)}
                    />
                    <IoTimerOutline />
                  </div>
                </div>
              </>
            )}

            {/* <button className="blue-btn-box flex-column" onClick={selectStartTime}>Start Time <IoTimerOutline/></button>
                    <button className="blue-btn-box flex-column" onClick={selectEndTime}>End Time <IoTimerOutline/></button> */}
          </div>
          <div>
            <button
              style={{ width: 'inherit' }}
              className='bluebtn reset-btn'
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
            <button
              style={{ width: 'inherit' }}
              className='bluebtn book-btn'
              onClick={BookRoom}
            >
              Book
            </button>
            {/* <button style={{width:"inherit"}} className='bluebtn book-btn' onClick={BookOutlook}>Book Event</button> */}
          </div>
        </div>
      </div>

      <div className='  border-radius5px'>
        <div className='table-container  box-shadow'>
          <div className='manament-table-header'>
            <div></div>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
          {/*  */}
          <>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // <>
                      <th {...column.getHeaderProps()}>
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        ></span>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                        <span
                          className='table-asc-dec'
                          onClick={() => column.toggleSortBy()}
                        >
                          {/* !column.isSortedDesc */}
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </span>
                      </th>

                      // {/* </> */}
                    ))}
                    <th>
                      <div className='margintop2rem'>Action</div>
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      <td className='action-table-btns'>
                        {/* <BiEditAlt {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' /> */}
                        {row.original.current_status === 'Reserved' ? (
                          <MdOutlineDoDisturbAlt
                            onClick={() => CancelBook(row.original)}
                            className='cursor-pointer'
                          />
                        ) : (
                          <MdOutlineDoDisturbAlt className='cursor-pointer gray' />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='pagination-section'>
              <div className='pagination-page-section'>
                <div>
                  <span>
                    Page
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
                <span>
                  | Go to page:{' '}
                  <input
                    type='number'
                    defaultValue={pageIndex + 1}
                    // min={pageIndex + 1}
                    max={pageOptions.length}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: '50px' }}
                  />
                </span>{' '}
              </div>

              <div className='pagination-btn-section'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <FiChevronsLeft />
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <FiChevronLeft /> <span>Previous</span>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <span>Next</span> <FiChevronRight />
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <FiChevronsRight />
                </button>
              </div>

              <div className='pagination-select-section'>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 15, 20, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
          {/*  */}
        </div>
      </div>

      {showAvailable && (
        <div className='book-room-model-wrapper'>
          <div className='book-room-model test'>
            <div className='book-room-model-header'>
              <h4>Available Seats</h4>
            </div>
            <div>
              {availableRoomData.map((d, i) => {
                return (
                  <div key={i}>
                    {i === 0 && (
                      <div className='data-section-wrapper backk'>
                        {/* <p className="data-section">device_id</p>
                              <p className="data-section">no_of_pax</p> */}
                        <p className='data-section'>Name</p>
                        {/* <p className="data-section">Description</p> */}
                        <p className='data-section'>Seats</p>
                        <p className='data-section'>Employee</p>
                        <p className='data-section'>Book</p>
                      </div>
                    )}
                    <div className='data-section-wrapper'>
                      {/* <div className="data-section">
                                <p>{d.device_id}</p> 
                                </div> 
                                <div className="data-section">
                                <p>{d.no_of_pax}</p> 
                                </div>  */}
                      <div className='data-section'>
                        <p>{d.table_name}</p>
                      </div>
                      {/* <div className="data-section">
                                <input type='text' className="Deskdesc" onChange={(e)=>setDescription(e.target.value)} value={Description}></input>
                                </div>  */}
                      <div className='data-section'>
                        {/* {d.seat.length>0?d.seat.map((d)=>{
                                return <>
                                {d.seat_name}<br/>
                                </>
                               }):'No Seats'} */}
                        {d.seat.length > 0 ? (
                          <select
                            name='cars'
                            id='seats'
                            style={{ border: 'none', padding: '1rem' }}
                            className=' '
                            onChange={(e) => setSeat(e.target.value)}
                            value={Seat}
                          >
                            <option value='' disabled selected>
                              Seats
                            </option>

                            {d.seat.map((d, i) => {
                              return (
                                <option
                                  key={i}
                                  disabled={
                                    d.status !== 'Available' ? true : false
                                  }
                                  value={d.seat_id}
                                >
                                  {d.seat_name}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          'No Seats'
                        )}
                      </div>
                      <div className='data-section'>
                        {/* {d.seat.length>0?d.seat.map((d)=>{
                                return <>
                                {d.seat_name}<br/>
                                </>
                               }):'No Seats'} */}
                        {d.seat.length > 0 ? (
                          <select
                            name='cars'
                            id='emp'
                            style={{ border: 'none', padding: '1rem' }}
                            className=' '
                            onChange={(e) => setEmp(e.target.value)}
                            value={Emp}
                          >
                            <option value='' disabled selected>
                              User
                            </option>

                            {userdata.map((d, i) => {
                              return (
                                <option key={i} value={d.email}>
                                  {d.email}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          'No Seats'
                        )}
                      </div>
                      <div>
                        <button
                          className='available-book-btn'
                          onClick={() => BookDesk(d)}
                        >
                          book
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='book-room-model-btn'>
              <button className='bluebtn reset-btn' onClick={cancel1}>
                Cancel
              </button>
              {/* <button className='bluebtn book-btn' onClick={bookRooms}>Book</button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeskManagement;
