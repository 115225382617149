
import { IoGridOutline } from 'react-icons/io5';
import { BsBarChart } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import { MdSettingsSuggest } from 'react-icons/md';
import { BiUserVoice } from 'react-icons/bi';
import { SiAuthy } from 'react-icons/si';

export const obj = [
    {  
    "id":1,
    "icon":<IoGridOutline/>,
    "menu_name":"Dashboard",
    "route":"/"
    },
    {  
        "id":2,
        "icon":<MdSettingsSuggest/>,
        "menu_name":"Booking Management",
        "route":"booking-management"
        },
        {  
        "id":3,
        "icon":<BiUserVoice/>,
        "menu_name":"User Management",
        "route":"user-management"
        },
        {  
            "id":10,
            "icon":<SiAuthy/>,
            "menu_name":"Visitor Management",
            "route":"vister-management"
        },
        // {  
        //     "id":9,
        //     "icon":<SiAuthy/>,
        //     "menu_name":"User Authentication",
        //     "route":"user-authentication"
        // },
        
        {  
        "id":4,
        "icon":<BiUserVoice/>,
        "menu_name":"Leave Management",
        "route":"user-approval"
        },
        {  
        "id":5,
        "icon":<BsBarChart/>,
        "menu_name":"Facility Master ",
        "route":"master-facility"
        },
        // {  
        // "id":6,
        // "icon":<MdOutlineMail/>,
        // "menu_name":"Reports",
        // "route":"reports"
        // },
        {  
        "id":7,
        "icon":<MdOutlineMail/>,
        "menu_name":"Live Capture",
        "route":"live-capture"
        },
        {  
        "id":8,
        "icon":<MdOutlineMail/>,
        "menu_name":"Email Log",
        "route":"email-log"
        }

]
