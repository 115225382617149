import api from 'api/auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};
class MeetingRoom {
  getFacility() {
    return api.post('/admin/facility/get', obj);
  }
  getroom() {
    return api.post('/room/get', obj);
  }
  getfloor() {
    return api.post('/room/floor', obj);
  }

  roomenable(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/enable', datafull);
  }
  roomdisable(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/disable', datafull);
  }

  roomdelete(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/delete', datafull);
  }

  roomcreate(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/add', datafull);
  }
  roomupdate(data) {
    const datafull = { ...data, ...obj };
    return api.post('/room/edit', datafull);
  }
}

export default new MeetingRoom();
