import api from './auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class PanelApi {
  getFacility() {
    return api.post('/admin/facility/get', obj);
  }

  getmaindoor() {
    return api.post('/maindoor/get', obj);
  }
  getfloor() {
    return api.post('/room/floor', obj);
  }

  deletemaindoor(data) {
    return api.post('/maindoor/delete', data);
  }

  addmaindoor(data) {
    const datafull = { ...data, ...obj };
    return api.post('/maindoor/add', datafull);
  }

  updatemaindoor(data) {
    return api.post('/maindoor/update', data);
  }
  openlocker(data) {
    return api.post('/maindoor/unlock', data);
  }
}

export default new PanelApi();
