import io from 'socket.io-client';
import apiauth from 'api/api';
import { Slide, toast } from 'react-toastify';
import { history } from 'App';
import { popUp } from '../components/Swal/Swal';

// const token = JSON.parse(localStorage.getItem('chopeaitoken'));
const token = JSON.parse(localStorage.getItem('chopeaitoken'));

const access_token = token?.accessToken;
export const sockets = io(`${process.env.REACT_APP_SOCKET}/companies/socket`, {
  withCredentials: true,
  auth: {
    token: access_token,
  },
});

// middleware of socket
sockets.off('error').on('error', async (data) => {
  const errormessage = data;
  const message = errormessage?.message;
  console.log(message);
  if (message === 'jwt expired') {
    const token = JSON.parse(localStorage.getItem('chopeaitoken'));
    if (token) {
      const { refreshToken } = token;

      await apiauth
        .refreshtoken({ refreshToken })
        .then((response) => {
          const access_token = response?.data?.data?.access_token ?? '';
          token.accessToken = access_token; // new token

          localStorage.setItem('chopeaitoken', JSON.stringify(token)); // update of access token only
          // window.location.reload(); // reload token expired again load refresh data
        })
        .catch((error) => {
          localStorage.removeItem('chopeaitoken');
          localStorage.removeItem('employee');
          localStorage.removeItem('companies_id');

          toast.error(error?.response?.data?.data?.message ?? error, {
            autoClose: 2000,
            transition: Slide,
          });
          history.push('/login');
          window.location.pathname = '/login';
        });
    } else {
      localStorage.removeItem('chopeaitoken');
      localStorage.removeItem('employee');
      localStorage.removeItem('companies_id');

      toast.error('token us empty', {
        autoClose: 2000,
        transition: Slide,
      });
      history.push('/login');
      window.location.pathname = '/login';
    }
    // const token = {
    //   refreshToken: '',
    // };
  } else {
    popUp.fire({
      title: message,
      icon: 'error',
    });
  }
});
