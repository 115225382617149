import React from 'react';
import ReactPichart from 'echarts-for-react';

const Piechartper = ({ color, data }) => {
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    color,
    legend: {
      show: false,

      labels: {},
      // labels: {
      //   colors: '#6E7079',
      //   useSeriesColors: false,
      // },
    },

    series: [
      {
        type: 'pie',
        radius: ['30%', '50%'],
        avoidLabelOverlap: false,

        labelLine: {
          length: 0,
          show: true,
          style: {
            color: 'rgba(30, 69, 74, 0.50)',
          },
        },

        label: {
          formatter: '{d}%',
          color: '#ffff',
          fontSize: '0.8rem',
          // borderColor: '#8C8D8E',

          rich: {
            // a: {
            //   color: '#6E7079',
            //   lineHeight: 22,
            //   align: 'center',
            // },
            // hr: {
            //   borderColor: '#8C8D8E',
            //   width: '100%',
            //   borderWidth: 1,
            //   height: 0,
            // },
            // b: {
            //   color: '#4C5058',
            //   fontSize: 14,
            //   fontWeight: 'bold',
            //   lineHeight: 10,
            // },
            // per: {
            //   color: '#fff',
            //   backgroundColor: '#4C5058',
            //   borderRadius: 0,
            // },
          },
        },

        data,
      },
    ],
  };
  return (
    <div>
      <ReactPichart
        style={{ width: '15rem', height: '13rem' }}
        option={option}
      />
    </div>
  );
};

export default Piechartper;
