import React, { useState } from 'react';
import { obj } from '../../helper/sideBarData';
import './sideBar.css';
import { NavLink } from 'react-router-dom';
import logo from '../../assests/logo.png';
import logo2 from '../../assests/logo_only.png';
import { FiLogOut } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function SideBar() {
  const [open, setopen] = useState(false);

  // Style Start
  const toggleMenu = () => {
    setopen(!open);
    if (!open) {
      document
        .querySelectorAll('.menu-name')
        .forEach((d) => (d.style.display = 'none'));
      document
        .querySelectorAll('.sign-btn-text')
        .forEach((d) => (d.style.display = 'none'));
      document.querySelector('.side-bar-wrapper').style.width = '100px';
      document.querySelector('.side-bar-wrapper').classList.add('anim');
      document.querySelector('.logo-container').style.width = '100%';
      document.querySelector('.logo-container').style.height = '100%';
      document
        .querySelectorAll('.nav-link')
        .forEach((d) => (d.style.justifyContent = 'center'));
      document
        .querySelectorAll('.nav-link')
        .forEach((d) => (d.style.padding = '1rem 0  1rem'));
      document.querySelectorAll('.nav-link').forEach((d, i) => {
        d.setAttribute(
          'data-title',
          document.querySelectorAll('.menu-name')[i].innerText
        );
      });
    } else {
      document
        .querySelectorAll('.menu-name')
        .forEach((d) => (d.style.display = 'block'));
      document
        .querySelectorAll('.sign-btn-text')
        .forEach((d) => (d.style.display = 'block'));
      document.querySelector('.side-bar-wrapper').style.width = 'auto';
      document.querySelector('.logo-container').style.width = '228px';
      document.querySelector('.logo-container').style.height = '82px';
      document
        .querySelectorAll('.nav-link')
        .forEach((d) => (d.style.justifyContent = ''));
      document
        .querySelectorAll('.nav-link')
        .forEach((d) => (d.style.padding = '1rem 0 1rem 1rem'));
      document.querySelector('.side-bar-wrapper').classList.remove('anim');
      document
        .querySelectorAll('.nav-link')
        .forEach((d) => d.removeAttribute('data-title'));
    }
  };
  // Style end
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <>
      <div className='side-bar-wrapper hidden'>
        <div className='side-bar-container '>
          <div className='logo-container'>
            <img src={!open ? logo : logo2} alt='logo' />
          </div>
          {obj.map((data) => {
            return (
              <NavLink
                to={data.route}
                data-title={data.menu_name}
                end
                className={({ isActive }) =>
                  isActive ? 'selected nav-link' : 'nav-link'
                }
                key={data.id}
              >
                {data.icon}
                <span className='menu-name'>{data.menu_name}</span>
              </NavLink>
            );
          })}

          <button className='sign-btn' onClick={logout}>
            <span className='sign-btn-icon'>
              <FiLogOut />
            </span>
            <span className='sign-btn-text'>Sign Out</span>
          </button>
        </div>
        <div className='toggle-btn-wrapper'>
          <button onClick={toggleMenu}>
            {!open ? <FaChevronLeft /> : <FaChevronRight />}
          </button>
        </div>
      </div>
    </>
  );
}

export default SideBar;
