import React, { useState, useMemo, forwardRef, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import '../Styles/global.css';
import '../Styles/BookingManagement.css';
import { AiOutlineCalendar } from 'react-icons/ai';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { IoTimerOutline } from 'react-icons/io5';
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
// import MOCK_DATA from '../helper/BookingManagement.json'
import { COLUMNS } from '../helper/columns';
import InputWithLable from '../components/input/InputWithLable';
import { GlobalFilter } from '../components/table_filter/GlobalFilter';
import { ColumnFilter } from '../components/table_filter/ColumnFilter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DropDownSelect from '../components/dropdownselect/DropDownSelect';
import BookingApi from 'api/bookingmanagement';
import { Toast, popUp } from '../components/Swal/Swal';
import { MdOutlineDoDisturbAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { sockets } from 'apisocket/socket';
function BookingManagement() {
  const renderAfterCalled = useRef(false);
  const [showRoom, setshowRoom] = useState(false);
  const [showAvailable, setshowAvailable] = useState(false);
  const [roomTableData, setRoomTableData] = useState([]);
  const [availableRoomData, setavailableRoomData] = useState([]);
  const [FloorName, setFloorName] = useState('');
  // const [departmentData ,setdepartmentData] = useState([])
  const [value, setvalue] = useState(new Date());
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => roomTableData, [roomTableData]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [userdata, setuserdata] = useState(null);
  const [Floor, setFloor] = useState([]);
  const [deviceId, setdeviceId] = useState();
  const [roomName, setroomName] = useState();
  const [pax, setPax] = useState();
  const [invite, setInvite] = useState();
  const [Description, setDescription] = useState('');
  const [no, setlimit] = useState(0);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const changeDate = (e) => {
    var date = moment(e).format('YYYY-MM-DD');
    console.log(date);
    setGlobalFilter(date);
    setvalue(e);
  };

  // const selectdate = ()=>{

  // }

  //
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;
  const { globalFilter } = state;
  const BookRoom = async () => {
    const obj = {
      start_date: moment(startDate).format('DD-MM-YYYY'),
      end_date: moment(endDate).format('DD-MM-YYYY'),
      start_time: moment(startTime).format('HH:mm'),
      end_time: moment(endTime).format('HH:mm'),
      floor_name: FloorName,
      type_user: 'Admin',
    };

    await BookingApi.getavailable(obj)
      .then(function (response) {
        setavailableRoomData(response?.data?.data?.availableroom ?? []);
        setshowAvailable(true);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });

    // setshowRoom(true)
  };
  const EditRoom = (d) => {
    console.log(d);
    // setName(d)
    setshowRoom(true);
    setshowAvailable(false);
    setroomName(d.room_name);
    setPax(d.no_of_pax);
    setdeviceId(d.device_id);
    //  selectPax()
    setlimit(d.no_of_pax);
    // setStartDate(d.reserved_date)
    // setStartDate
  };
  const cancel = () => {
    setshowRoom(false);
    // setlimit(pax)
  };
  const cancel1 = () => {
    setshowAvailable(false);
  };

  const BookOutlook = async () => {
    var empEmail = JSON.parse(localStorage.getItem('employee'));
    var obj = {
      device_id: deviceId,
      room_name: roomName,
      no_of_pax: pax,
      list_of_pax: invite.length,
      invite_member: invite,
      start_date: moment(startDate).format('DD-MM-YYYY'),
      end_date: moment(endDate).format('DD-MM-YYYY'),
      start_time: moment(startTime).format('HH:mm'),
      end_time: moment(endTime).format('HH:mm'),
      floor_name: FloorName,
      organizer_email: empEmail.employee.email,
      description: Description,
      type_user: 'Admin',
    };

    await BookingApi.meetingroombooking(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getdata();
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });

    // setRoomTableData(roomData.data.getbooking)
    setshowRoom(false);
  };
  const selectPax = () => {
    var e = document.getElementById('Pax');
    // var value = e.value;
    var text = e.options[e.selectedIndex].text;
    console.log(text);
    setlimit(text);
    console.log(no);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const StartTimeGet = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const EndTimeGet = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  //

  const CancelBook = async (d) => {
    var obj = {
      booking_id: d.id,
    };
    await BookingApi.meetingcancel(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response.data.data.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getdata();
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  const getFoolr = async () => {
    await BookingApi.getfloor()
      .then(function (response) {
        setFloor(response?.data?.data?.floor ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };

  const getdata = async () => {
    await BookingApi.getbooking()
      .then(function (response) {
        setRoomTableData(response?.data?.data?.getbooking ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  const getemail = async () => {
    await BookingApi.getemail()
      .then(function (response) {
        setuserdata(response?.data?.data?.data ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error.response.data.data.message,
          icon: 'error',
        });
      });
  };
  useEffect(() => {
    if (window.location.pathname === '/booking-management') {
      document.getElementById('MeetingRoom').style.backgroundColor = '#394A7A';
    }
    if (!renderAfterCalled.current) {
      getemail();
      getFoolr();
      getdata();
      sockets.off('adminroomstatus').on('adminroomstatus', async (data) => {
        if (data) {
          console.log('reload meeting room booking');
          getdata();
        }
      });
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <>
      <div className='app-header'>
        <h1>Booking Management</h1>
      </div>
      <div className='management-flex'>
        <div className='head-calender'>
          <Calendar onChange={changeDate} value={value} className='calender' />
        </div>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/booking-management'>
              <button
                id='MeetingRoom'
                className='add-new report-btn-item blue-btn-box active'
              >
                Meeting Room
              </button>
            </Link>
            <Link to='/desk-management'>
              <button
                id='desk'
                className='add-new report-btn-item blue-btn-box active'
              >
                Hot Desking
              </button>
            </Link>
          </div>
          <div className='management-dropdown'>
            {/* blue-btn-box */}
            <div className='input-withlable-container flex-column'>
              <p className='date-heading'>Floor</p>
              <div className='border-radius5px date-c'>
                <select
                  name='cars'
                  id='cars'
                  style={{ border: 'none', padding: '1rem' }}
                  className=' '
                  onChange={(e) => setFloorName(e.target.value)}
                  value={FloorName}
                >
                  <option value='' disabled selected>
                    Floor
                  </option>

                  {Floor.map((d, i) => {
                    return (
                      <option key={i} value={d.name}>
                        {d.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className='  flex-column'>
              <p className='date-heading'>Start Date</p>
              <div className='border-radius5px date-c'>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={<ExampleCustomInput />}
                  // timeFormat="dd/mm/yyyy"
                  dateFormat='dd/MM/yyyy'
                  minDate={moment().toDate()}
                />
                <AiOutlineCalendar />
              </div>
            </div>
            <div className='flex-column'>
              <p className='date-heading'>End Date</p>
              <div className='border-radius5px  date-c'>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  customInput={<ExampleCustomInput />}
                  // timeFormat="dd/mm/yyyy"
                  dateFormat='dd/MM/yyyy'
                  minDate={startDate}
                />
                <AiOutlineCalendar />
              </div>
            </div>
            <div className='flex-column'>
              <p className='date-heading'>Start Time</p>
              <div className='border-radius5px date-c'>
                <DatePicker
                  selected={startTime}
                  onChange={(time) => setStartTime(time)}
                  customInput={<StartTimeGet />}
                  dateFormat='h:mm aa'
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  minTime={
                    new Date().getDate() ===
                    new Date(startTime ?? new Date().getTime()).getDate()
                      ? new Date()
                      : new Date().setHours(0, 0, 0)
                  }
                  maxTime={new Date().setHours(23, 59, 59)}
                  timeCaption='Start Time'
                />
                <IoTimerOutline />
              </div>
            </div>
            <div className='flex-column'>
              <p className='date-heading'>End Time</p>
              <div className='border-radius5px date-c'>
                <DatePicker
                  selected={endTime}
                  onChange={(time) => setEndTime(time)}
                  customInput={<EndTimeGet />}
                  dateFormat='h:mm aa'
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption='End Time'
                  minTime={
                    new Date().getDate() ===
                    new Date(endTime ?? new Date().getTime()).getDate()
                      ? new Date()
                      : new Date().setHours(0, 0, 0)
                  }
                  maxTime={new Date().setHours(23, 59, 59)}
                />
                <IoTimerOutline />
              </div>
            </div>

            {/* <button className="blue-btn-box flex-column" onClick={selectStartTime}>Start Time <IoTimerOutline/></button>
                    <button className="blue-btn-box flex-column" onClick={selectEndTime}>End Time <IoTimerOutline/></button> */}
          </div>
          <div>
            <button
              style={{ width: 'inherit' }}
              className='bluebtn reset-btn'
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
            <button
              style={{ width: 'inherit' }}
              className='bluebtn book-btn'
              onClick={BookRoom}
            >
              Book
            </button>
            {/* <button style={{width:"inherit"}} className='bluebtn book-btn' onClick={BookOutlook}>Book Event</button> */}
          </div>
        </div>
      </div>

      <div className='border-radius5px'>
        <div className='table-container  box-shadow'>
          <div className='manament-table-header'>
            <div></div>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
          {/*  */}
          <>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // <>
                      <th {...column.getHeaderProps()}>
                        <span
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        ></span>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                        <span
                          className='table-asc-dec'
                          onClick={() => column.toggleSortBy()}
                        >
                          {/* !column.isSortedDesc */}
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </span>
                      </th>

                      // {/* </> */}
                    ))}
                    <th>
                      <div className='margintop2rem'>Action</div>
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                      <td className='action-table-btns'>
                        {/* <BiEditAlt {...row.getRowProps()} onClick={()=>EditRoom(row.original)} className='cursor-pointer' /> */}
                        {row.original.current_status === 'Reserved' ? (
                          <MdOutlineDoDisturbAlt
                            onClick={() => CancelBook(row.original)}
                            className='cursor-pointer'
                          />
                        ) : (
                          <MdOutlineDoDisturbAlt className='cursor-pointer gray' />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='pagination-section'>
              <div className='pagination-page-section'>
                <div>
                  <span>
                    Page
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
                <span>
                  | Go to page:{' '}
                  <input
                    type='number'
                    defaultValue={pageIndex + 1}
                    // min={pageIndex + 1}
                    max={pageOptions.length}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: '50px' }}
                  />
                </span>{' '}
              </div>

              <div className='pagination-btn-section'>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  <FiChevronsLeft />
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <FiChevronLeft /> <span>Previous</span>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <span>Next</span> <FiChevronRight />
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <FiChevronsRight />
                </button>
              </div>

              <div className='pagination-select-section'>
                <select
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 15, 20, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
          {/*  */}
        </div>
      </div>

      {showRoom && (
        <div className='book-room-model-wrapper'>
          <div className='book-room-model'>
            <div className='book-room-model-header'>
              <h4>{roomName} Booking</h4>
            </div>
            {/* <InputWithLable placeholder='Subject' callback={(event)=>setName(event.target.value)} value={name}/> */}
            <InputWithLable
              placeholder='Description'
              callback={(event) => setDescription(event.target.value)}
              value={Description}
            />
            <div className='input-withlable-container '>
              <span>No of PAX</span>
              <select
                name='cars'
                id='Pax'
                placeholder='Select Pax'
                onChange={selectPax}
                className='select-box-shadow usermangement-room-book-btn report-department-select'
              >
                <option disabled selected>
                  Select Pax Default {pax}
                </option>
                {[...Array(pax)].map((d, i) => {
                  return (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <InputWithLable placeholder='invitees' callback={(event)=>setInvite(event.target.value) } value={invite}/> */}
            <div className='input-withlable-container '>
              <span>Invitees</span>
              <DropDownSelect
                limit={no}
                data={userdata}
                remove={(s, r) => setInvite(s)}
                callback={(d) => setInvite(d)}
              />
            </div>
            <InputWithLable
              bool={true}
              placeholder='Booked Date'
              value={moment(startDate).format('DD/MM/YYYY')}
            />
            {/* 
                        callback={(event)=>setBdate(startDate) } 
                        callback={(event)=>setStime(startTime) }
                        callback={(event)=>setEtime(endTime) }
                        */}
            <InputWithLable
              bool={true}
              placeholder='Start Time'
              value={moment(startTime).format('hh:mm:a')}
            />
            <InputWithLable
              bool={true}
              placeholder='End Time'
              value={moment(endTime).format('hh:mm:a')}
            />
            {/* {moment(startDate).format("YYYY-MM-DD") !== moment(endDate).format("YYYY-MM-DD") && <div className="days-selector">
                          <p onClick={(e)=>addDays('Sunday',e)}>S</p>
                          <p onClick={(e)=>addDays('Monday',e)}>M</p>
                          <p onClick={(e)=>addDays('Tuesday',e)}>T</p>
                          <p onClick={(e)=>addDays('Wednesday',e)}>W</p>
                          <p onClick={(e)=>addDays('Thursday',e)}>T</p>
                          <p onClick={(e)=>addDays('Friday',e)}>F</p>
                          <p onClick={(e)=>addDays('Saturday',e)}>S</p>
                        </div> } */}
            {/* <InputWithLable placeholder='Status' callback={(event)=>setStatus(event.target.value) } value={status}/> */}
            <div className='book-room-model-btn'>
              <button className='bluebtn reset-btn' onClick={cancel}>
                Cancel
              </button>
              <button className='bluebtn book-btn' onClick={BookOutlook}>
                Book
              </button>
            </div>
          </div>
        </div>
      )}

      {showAvailable && (
        <div className='book-room-model-wrapper'>
          <div className='book-room-model test'>
            <div className='book-room-model-header'>
              <h4>Available Rooms</h4>
            </div>
            <div>
              {availableRoomData.map((d, i) => {
                return (
                  <div key={i}>
                    {i === 0 && (
                      <div className='data-section-wrapper backk'>
                        {/* <p className="data-section">device_id</p>
                              <p className="data-section">no_of_pax</p> */}
                        <p className='data-section'>Room Name</p>
                        <p className='data-section'>Status</p>
                        <p className='data-section'>Book</p>
                      </div>
                    )}
                    <div className='data-section-wrapper'>
                      {/* <div className="data-section">
                                <p>{d.device_id}</p> 
                                </div> 
                                <div className="data-section">
                                <p>{d.no_of_pax}</p> 
                                </div>  */}
                      <div className='data-section'>
                        <p>{d.room_name}</p>
                      </div>
                      <div className='data-section'>
                        <p>{d.status}</p>
                      </div>
                      <div>
                        <button
                          className='available-book-btn'
                          onClick={() => EditRoom(d)}
                        >
                          book
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='book-room-model-btn'>
              <button className='bluebtn reset-btn' onClick={cancel1}>
                Cancel
              </button>
              {/* <button className='bluebtn book-btn' onClick={bookRooms}>Book</button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BookingManagement;
