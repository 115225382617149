import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Panel.css';
import InputWithLable from '../components/input/InputWithLable';

import { AiOutlineDelete } from 'react-icons/ai';
import { RiPhoneCameraLine } from 'react-icons/ri';
import { BiEditAlt } from 'react-icons/bi';
import panel from 'api/panel';
import { Toast, popUp } from '../components/Swal/Swal';

function Panel() {
  const renderAfterCalled = useRef(false);
  const [LockerData, setLockerData] = useState([]);
  const [showRoom, setshowRoom] = useState(false);
  const [FloorName, setFloorName] = useState('');
  const [Description, setDescription] = useState('');
  const [Password, setPassword] = useState('');
  const [Email, setEmail] = useState('');
  const [Stopic, setStopic] = useState('');
  const [Ptopic, setPtopic] = useState('');
  const [Dtopic, setDtopic] = useState('');
  const [LockerNumger, setLockerNumger] = useState('');
  const [Type, setType] = useState('');
  const [Msg, setMsg] = useState('');
  const [room, setroom] = useState(false);

  const [Floor, setFloor] = useState([]);
  const [facility, setfacility] = useState([]);
  const [LockerDataOne, setLockerDataOne] = useState([]);
  const [Zone, setZone] = useState('');
  const [DoorType, setDoorType] = useState('');

  const getData1 = async () => {
    await panel
      .getFacility()
      .then(function (response) {
        var data = response.data?.data?.facility?.filter((d) => {
          return d.facility_name === 'main door';
        });
        console.log(data[0].authentication);
        setfacility(data[0].authentication);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getData = async () => {
    await panel
      .getmaindoor()
      .then(function (response) {
        setLockerData(response?.data?.data?.maindoor ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getFoolr = async () => {
    await panel
      .getfloor()
      .then(function (response) {
        setFloor(response?.data?.data?.floor ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const deleteLocker = async (d) => {
    var obj = {
      companies_id: d.companies_id,
      maindoor_id: d._id,
    };

    await panel
      .deletemaindoor(obj)
      .then(function (response) {
        Toast.fire({
          icon: 'success',
          title: response?.data?.data?.message,
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const UpdateShow = async (d) => {
    setshowRoom(true);
    setroom(true);
    console.log(d);
    setFloorName(d.floor_name);
    setStopic(d.subscribes_topic);
    setPtopic(d.publish_topic);
    setDtopic(d.device_topic);
    setMsg(d.unlock_message);
    setLockerNumger(d.locker_no);
    setZone(d.zone_id);
    setType(d.locker_type);
    setDescription(d.door_name);
    setEmail(d.device_id);
    setPassword(d.password);
    setLockerDataOne(d);
  };

  const cancel = () => {
    setshowRoom(false);
    setFloorName('');
    setDescription('');
    setPassword('');
    setEmail('');
    setStopic('');
    setPtopic('');
    setDtopic('');
    setMsg('');
    setLockerNumger('');
    setZone('');
    setType('');
    setroom(false);
  };

  const createRoom = async () => {
    var facility = document.getElementsByName('facility');
    const access = [];
    console.log(facility[0].id);
    facility.forEach((d) => {
      if (d.checked) {
        var dd = {
          auth_id: d.id,
          auth_name: d.value,
        };
      }
      // console.log(dd);
      access.push(dd);
    });

    console.log(access);
    if (FloorName === '' || FloorName === null || FloorName === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Select Floor',
      });
    } else if (
      Description === '' ||
      Description === null ||
      Description === undefined
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Door Name',
      });
    } else if (Email === '' || Email === null || Email === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Device ID',
      });
    } else if (Password === '' || Password === null || Password === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Password',
      });
    } else if (Stopic === '' || Stopic === null || Stopic === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Subscribes Topic',
      });
    } else if (Ptopic === '' || Ptopic === null || Ptopic === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Publish Topic',
      });
    } else if (Dtopic === '' || Dtopic === null || Dtopic === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Device Topic',
      });
    } else if (Msg === '' || Msg === null || Msg === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Message',
      });
    } else if (DoorType === '' || DoorType === null || DoorType === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Select Door Type',
      });
    } else {
      var obj = {
        // "locker_no": LockerNumger,
        // "locker_type":Type,
        // "zone_id": Zone,
        floor_name: FloorName,
        subscribes_topic: Stopic,
        publish_topic: Ptopic,
        device_topic: Dtopic,
        unlock_message: Msg,
        authentication: access,
        device_id: Email,
        door_name: Description,
        password: Password,
        type: DoorType,
        //   "authentication": [
        //     {
        //         "auth_id": "6362a43c03a71f9735fded34",
        //         "auth_name": "passcode"
        //     }
        // ]
      };
      await panel
        .addmaindoor(obj)
        .then(function (response) {
          console.log(response.data?.data);
          Toast.fire({
            title: 'success!',
            text: response?.data?.data?.message,
            icon: 'success',
            confirmButtonText: "Let's go",
          });
          getData();
          setshowRoom(false);
          cancel();
        })
        .catch(function (error) {
          console.log(error.response.data.data.message);
          popUp.fire({
            title: error?.response?.data?.data?.message ?? error,
            icon: 'error',
          });
        });
    }
  };
  const UpdateRoom = async (d) => {
    setshowRoom(true);
    setroom(true);
    var facility = document.getElementsByName('facility');
    const access = [];
    console.log(facility[0].id);
    facility.forEach((d) => {
      if (d.checked) {
        var dd = {
          auth_id: d.id,
          auth_name: d.value,
        };
      }
      // console.log(dd);
      access.push(dd);
    });

    var obj = {
      companies_id: LockerDataOne.companies_id,
      maindoor_id: LockerDataOne._id,
      locker_no: LockerNumger,
      locker_type: Type,
      zone_id: Zone,
      floor_name: FloorName,
      subscribes_topic: Stopic,
      publish_topic: Ptopic,
      device_topic: Dtopic,
      unlock_message: Msg,
      device_id: Email,
      type: DoorType,
      password: Password,
      door_name: Description,
      authentication: access,
    };

    await panel
      .updatemaindoor(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
        setshowRoom(false);
        cancel();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const addLocker = () => {
    setshowRoom(true);
  };

  const openLock = async (d) => {
    // eslint-disable-next-line no-restricted-globals
    let ifok = confirm('Are You Sure To Open Locker');
    if (ifok) {
      var obj = {
        companies_id: d.companies_id,
        maindoor_id: d._id,
      };
      await panel
        .openlocker(obj)
        .then(function (response) {
          Toast.fire({
            title: 'success!',
            text: response?.data?.data?.message,
            icon: 'success',
            confirmButtonText: "Let's go",
          });
          setTimeout(() => {
            getData();
          }, 3000);
        })
        .catch(function (error) {
          console.log(error.response.data.data.message);
          popUp.fire({
            title: error?.response?.data?.data?.message ?? error,
            icon: 'error',
          });
        });
    } else {
      alert('You Revert The Process');
    }
  };
  useEffect(() => {
    if (window.location.pathname === '/panel') {
      document.getElementById('Panel').style.backgroundColor = '#394A7A';
    }
    if (!renderAfterCalled.current) {
      getData1();
      getFoolr();
      getData();
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <>
      <div className='appHeader'>
        <p>Facility Master</p>
      </div>
      <div className='management-flex'>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/master-facility'>
              <button
                id='MeetingRoom'
                className='add-new report-btn-item blue-btn-box active'
              >
                Meeting Room
              </button>
            </Link>
            <Link to='/locker'>
              <button
                id='Locker'
                className='add-new report-btn-item blue-btn-box active'
              >
                Locker
              </button>
            </Link>
            <Link to='/panel'>
              <button
                id='Panel'
                className='add-new report-btn-item blue-btn-box active'
              >
                Door Panel
              </button>
            </Link>
            <Link to='/desk'>
              <button
                id='Panel'
                className='add-new report-btn-item blue-btn-box active'
              >
                Hot Desking
              </button>
            </Link>
            {/* <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button>
                    <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button>
                    <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button> */}
          </div>
        </div>
      </div>
      <div className=''>
        <div className='manament-table-header'>
          <div className='btn-divder'>
            <button className='add-new-btn' onClick={addLocker}>
              Add Panel
            </button>
            {/* <button className='add-new-btn' >Remove Locker</button> */}
            {/* <div className='input-container'>

                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setfilterZone(e.target.value)} value={filterZone}>
                <option value=""  selected >All</option> 

                { ZoneList.map((d,i)=>{
                return  <option key={i} value={d._id}>{d.name}</option>
                })
                }
                </select>
                </div>
                <div className='input-container'>

                  <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setfilterLocker(e.target.value)} value={filterLocker}>
                  <option value=""  selected >All</option> 


                  { LockerType.map((d,i)=>{
                  return  <option key={i} value={d.name}>{d.name}</option>
                  })
                  }
                  </select>
                  </div> */}
          </div>
        </div>

        <div className='table-container '>
          <div className='panel-list'>
            {LockerData.length > 0 ? (
              LockerData.map((d, i) => {
                return (
                  <>
                    <div
                      key={i}
                      className={
                        d.status === 'Available'
                          ? 'Available'
                          : d.status === 'Occupied'
                          ? 'Occupied'
                          : null
                      }
                    >
                      <span>
                        <div>
                          <AiOutlineDelete onClick={() => deleteLocker(d)} />
                          {/* <HiOutlineUserRemove onClick={()=>RemoveUser(d)}/>
                                  <HiOutlineUserAdd onClick={()=>showAddUser(d)}/> */}

                          <BiEditAlt onClick={() => UpdateShow(d)} />
                        </div>
                      </span>

                      <div className='unlock' onClick={() => openLock(d)}>
                        <RiPhoneCameraLine />
                      </div>
                      <p className='tiny-text'>{d.door_name}</p>
                    </div>
                  </>
                );
              })
            ) : (
              <>No Panel Found</>
            )}
          </div>
        </div>
      </div>
      {showRoom && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Create Panel</h3>
              <div className='company-detail-list'>
                {/* <InputWithLable placeholder='Meeting Room Name' callback={(event)=>setRoomName(event.target.value)} value={RoomName}/> */}
                {/* <InputWithLable placeholder='Locker Number: ' type='number' callback={(event)=>setLockerNumger(event.target.value)} value={LockerNumger}/> */}
                <div className='input-withlable-container '>
                  <span>Floor</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setFloorName(e.target.value)}
                      value={FloorName}
                    >
                      <option value='' disabled selected>
                        Floor
                      </option>

                      {Floor.map((d, i) => {
                        return (
                          <option key={i} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {/* <div className='input-withlable-container '>
        <span>Zone</span>
        
        <div className='input-container'>

                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setZone(e.target.value)} value={Zone}>
                <option value="" disabled selected >Zone</option> 
                
                { ZoneList.map((d,i)=>{
              return  <option key={i} value={d._id}>{d.name}</option>
                })
                }
                </select>
              </div>
       
        </div> */}

                {/* <div className='input-withlable-container '>
        <span>LockerType</span>
        
        <div className='input-container'>

                <select name="cars" id="cars" className='input-field simple-select' onChange={(e)=>setType(e.target.value)} value={Type}>
                <option value="" disabled selected >Locker Type</option> 
                
                { LockerType.map((d,i)=>{
              return  <option key={i} value={d.name}>{d.name}</option>
                })
                }
                </select>
              </div>
       
        </div> */}

                <InputWithLable
                  placeholder='Door Name:'
                  callback={(event) => setDescription(event.target.value)}
                  value={Description}
                />
                <InputWithLable
                  placeholder='Device Id: '
                  callback={(event) => setEmail(event.target.value)}
                  value={Email}
                />
                <InputWithLable
                  placeholder='Password:'
                  callback={(event) => setPassword(event.target.value)}
                  value={Password}
                />
                {/* <InputWithLable placeholder='No of Packs: ' callback={(event)=>setPacks(event.target.value)} value={Packs}/> */}
                <InputWithLable
                  placeholder='subscribe Topic:'
                  callback={(event) => setStopic(event.target.value)}
                  value={Stopic}
                />
                <InputWithLable
                  placeholder='Publish topic:'
                  callback={(event) => setPtopic(event.target.value)}
                  value={Ptopic}
                />
                <InputWithLable
                  placeholder='Device Topic: '
                  callback={(event) => setDtopic(event.target.value)}
                  value={Dtopic}
                />
                <InputWithLable
                  placeholder='Message: '
                  callback={(event) => setMsg(event.target.value)}
                  value={Msg}
                />
                <div className='input-withlable-container subplan'>
                  <span>Door Type</span>
                  <select
                    name='cars'
                    id='cars'
                    className='input-field'
                    onChange={(e) => setDoorType(e.target.value)}
                    value={DoorType}
                  >
                    <option value='' disabled selected>
                      Status
                    </option>
                    <option value='IN'>IN</option>
                    <option value='OUT'>OUT</option>
                  </select>
                </div>
                <div className='facility-section'>
                  <span className='header'>Authentication</span>
                  <div className='flex-column-with-row'>
                    <div>
                      {/* <div className='align-center'>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                <label htmlFor="vehicle1">Face Authentication</label>
                </div>
                <div className='align-center'>
                <input type="checkbox" id="vehicle2" name="vehicle2" value="Car"/>
                <label htmlFor="vehicle2">Passcode Authentication</label>
                </div>
                <div className='align-center'>
                <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat"/>
                <label htmlFor="vehicle3">RF ID</label>
                </div>
                <div className='align-center'>
                <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat"/>
                <label htmlFor="vehicle3">QR Scanner</label>
                </div> */}

                      {facility.map((d) => {
                        return (
                          <>
                            <div className='align-center'>
                              <input
                                type='checkbox'
                                id={d.auth_id}
                                name='facility'
                                value={d.auth_name}
                                onChange={(e) => console.log(e.target.checked)}
                              />
                              <label htmlFor='facility'>{d.auth_name}</label>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>
                    Cancel
                  </button>
                  {!room ? (
                    <button className='create-btn' onClick={createRoom}>
                      Create
                    </button>
                  ) : (
                    <button className='create-btn' onClick={UpdateRoom}>
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Panel;
