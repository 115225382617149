import React from 'react';
import ReactPichart from 'echarts-for-react';

const piechart1 = ({ color, data }) => {
  const option = {
    tooltip: {
      trigger: 'item',
    },
    color,

    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        startAngle: 360,
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 2,
          borderColor: 'rgba(30, 69, 74, 0.50)',
          borderWidth: 1,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 25,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };

  return (
    <div>
      <ReactPichart
        style={{ width: '10rem', height: '10rem' }}
        option={option}
      />
    </div>
  );
};

export default piechart1;
