import api from 'api/auth';

var obj = {
  companies_id: localStorage.getItem('companies_id'),
};

class VisitorManagement {
  createvisitor(data) {
    return api.post('/admin/visitor/create', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updatevisitor(data) {
    return api.post('/admin/visitor/update', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deletevisitor(data) {
    const fulldata = { ...data, ...obj };
    return api.post('/admin/visitor/delete', fulldata);
  }

  visitorreject(data) {
    const fulldata = { ...data, ...obj };
    return api.post('/admin/visitor/reject', fulldata);
  }

  visitorapprovallist() {
    return api.post('/admin/visitor/approval', obj);
  }

  visitorapproval(data) {
    const fulldata = { ...data, ...obj };
    return api.post('/admin/visitor/visitorapproval', fulldata);
  }

  visitornotapprovallist() {
    return api.post('/admin/visitor/notapproval', obj);
  }
}

export default new VisitorManagement();
