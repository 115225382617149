import React, { useState } from 'react';
import '../Styles/Login.css';
import logo from '../assests/logo.png';
import InputField from '../components/input/InputField';
import { VscLock } from 'react-icons/vsc';
import { BiUser } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { Toast, popUp } from '../components/Swal/Swal';
import apiuser from 'api/api';

function Login() {
  const [name, setName] = useState('');
  const [password, setpassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!name) {
      alert('Please Enter Name');
    } else if (!password) {
      alert('Please Enter Password');
    } else {
      var obj = {
        email: name,
        password,
      };
      await apiuser
        .login(obj)
        .then(function (response) {
          Toast.fire({
            title: 'success!',
            text: response.data.data.message,
            icon: 'success',
            confirmButtonText: "Let's go",
          });
          localStorage.setItem('employee', JSON.stringify(response.data.data));
          const Token = response?.data?.data?.token ?? null;
          localStorage.setItem('chopeaitoken', JSON.stringify(Token));
          localStorage.setItem(
            'companies_id',
            response.data.data.employee.companies_id
          );
          navigate('/');
          window.location.reload()
          // if (response.status === 404) {
          //   alert(response.data.message)
          // }
        })
        .catch(function (error) {
          popUp.fire({
            title: error.response.data.data.message,
            text: 'Please Fill Details Correctly',
            icon: 'error',
          });
        });
    }
  };
  return (
    <div className='login-wrapper'>
      <div className='login-container border-blue'>
        <div className='loin-logo'>
          <img src={logo} alt='logo' />
        </div>
        <p>Welcome to Chope AI</p>
        <h6>Sign in to continue</h6>
        <InputField
          icon={<BiUser />}
          placeholder='UserName'
          type='text'
          callback={(event) => setName(event.target.value)}
          value={name}
        />
        <InputField
          icon={<VscLock />}
          placeholder='Password'
          type='password'
          callback={(event) => setpassword(event.target.value)}
          value={password}
        />
        {/* <input placeholder='userName' type='text'/>
      <input placeholder='password' type='password'/> */}
        <button className='sign-in-btn' onClick={handleLogin}>
          Sign In
        </button>
        <h2>
          <span>OR</span>
        </h2>
        <h6>
          {' '}
          <Link to='/forget-password'>Forget Password?</Link>
        </h6>
        {/* <h6>Don't have an account ? <Link to='/register'>Register</Link></h6>   */}
      </div>
    </div>
  );
}

export default Login;
