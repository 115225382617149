import api from './auth';

class AllApi {
  login(user) {
    return api.post('/employee/login', user);
  }
  refreshtoken(token) {
    return api.post('/utils/adminrefreshToken', token);
  }
}

export default new AllApi();
