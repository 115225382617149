import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IoMdLock, IoMdUnlock } from 'react-icons/io';
import '../Styles/Locker.css';
import InputWithLable from '../components/input/InputWithLable';

import { Toast, popUp } from '../components/Swal/Swal';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { HiOutlineUserAdd, HiOutlineUserRemove } from 'react-icons/hi';
import DropDownSelect from '../components/dropdownselect/DropDownSelect';
import { useLocation } from 'react-router-dom';
import locker from 'api/locker';
import { sockets } from 'apisocket/socket';

function Locker() {
  const renderAfterCalled = useRef(false);
  const [showRoom, setshowRoom] = useState(false);
  const [FloorName, setFloorName] = useState('');
  const [Stopic, setStopic] = useState('');
  const [Ptopic, setPtopic] = useState('');
  const [Dtopic, setDtopic] = useState('');
  const [LockerNumger, setLockerNumger] = useState('');
  const [Type, setType] = useState('');
  const [Msg, setMsg] = useState('');
  const [room, setroom] = useState(false);
  const [room1, setroom1] = useState(false);

  const [user, setUser] = useState(false);
  const [showZone, setshowZone] = useState(false);
  const [showZoneUpdate, setshowZoneUpdate] = useState(false);

  const [Floor, setFloor] = useState([]);
  const [facility, setfacility] = useState([]);
  const [LockerData, setLockerData] = useState([]);
  const [LockerType, setLockerType] = useState([]);
  const [LockerDataOne, setLockerDataOne] = useState([]);
  const [ZoneList, setZoneList] = useState([]);
  const [Zone, setZone] = useState('');
  const [filterZone, setfilterZone] = useState('');
  const [filterLocker, setfilterLocker] = useState('');

  const [ZoneId, setZoneId] = useState('');
  const [ZoneName, setZoneName] = useState('');
  const [ZonePassword, setZonePassword] = useState('');
  const [ZoneUpId, setZoneUpId] = useState('');

  const [invite, setInvite] = useState();
  const [userdata, setuserdata] = useState(null);

  const location = useLocation();
  const addLocker = () => {
    setroom(false);
    setshowRoom(true);
  };

  const addZone = () => {
    setshowZone(true);
  };
  const detData = async () => {
    await locker
      .getemail()
      .then(function (response) {
        setuserdata(response?.data?.data?.data ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getData1 = async () => {
    await locker
      .getFacility()
      .then(function (response) {
        var data = response?.data?.data?.facility?.filter((d) => {
          return d.facility_name === 'locker';
        });
        setfacility(data[0].authentication);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };
  const getZone = async () => {
    await locker
      .getzone()
      .then(function (response) {
        //  var data =  response.data?.data?.facility?.filter((d)=>{
        //    return d.facility_name ==="locker"
        //   })
        // console.log(data[0].authentication);
        setZoneList(response?.data?.data?.zones ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };
  const getData = async () => {
    await locker
      .getlocker()
      .then(function (response) {
        //  var data =  response.data?.data?.facility?.filter((d)=>{
        //    return d.facility_name ==="locker"
        //   })

        setLockerData(response.data?.data?.locker);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const filterData = async () => {
    var obj = {
      zone_id: filterZone,
      locker_type: filterLocker,
    };

    await locker
      .getfilterdata(obj)
      .then(function (response) {
        setLockerData(response?.data?.data?.locker ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };
  const getType = async () => {
    //   var obj = {
    //     "companies_id": localStorage.getItem('companies_id')
    // }
    await locker
      .gettypelocker()
      .then(function (response) {
        setLockerType(response?.data?.data?.locker_type ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const getFoolr = async () => {
    await locker
      .getfloor()
      .then(function (response) {
        setFloor(response?.data?.data?.floor ?? []);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message ?? error,
          icon: 'error',
        });
      });
  };

  const createRoom = async () => {
    if (
      LockerNumger === '' ||
      LockerNumger === null ||
      LockerNumger === undefined
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Locker Number',
      });
    } else if (
      FloorName === '' ||
      FloorName === null ||
      FloorName === undefined
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Please Select Floor',
      });
    } else if (Type === '' || Type === null || Type === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Select Locker Type',
      });
    } else if (Stopic === '' || Stopic === null || Stopic === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Subscribes Topic',
      });
    } else if (Ptopic === '' || Ptopic === null || Ptopic === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Publish Topic',
      });
    } else if (Dtopic === '' || Dtopic === null || Dtopic === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Device Topic',
      });
    } else if (Msg === '' || Msg === null || Msg === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Message',
      });
    } else {
      var obj = {
        locker_no: LockerNumger,
        locker_type: Type,
        zone_id: Zone,
        floor_name: FloorName,
        subscribes_topic: Stopic,
        publish_topic: Ptopic,
        device_topic: Dtopic,
        unlock_message: Msg,
      };
      await locker
        .createlocker(obj)
        .then(function (response) {
          Toast.fire({
            title: 'success!',
            text: response?.data?.data?.message,
            icon: 'success',
            confirmButtonText: "Let's go",
          });
          getData();
          setshowRoom(false);
          cancel();
        })
        .catch(function (error) {
          popUp.fire({
            title: error.response.data.data.message,
            text: 'Please Fill Details Correctly',
            icon: 'error',
          });
        });
    }
  };
  const UpdateRoom = async (d) => {
    setshowRoom(true);
    setroom(true);
    var obj = {
      companies_id: LockerDataOne.companies_id,
      locker_id: LockerDataOne._id,
      locker_no: LockerNumger,
      locker_type: Type,
      zone_id: Zone,
      floor_name: FloorName,
      subscribes_topic: Stopic,
      publish_topic: Ptopic,
      device_topic: Dtopic,
      unlock_message: Msg,
    };

    await locker
      .updatelocker(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
        setshowRoom(false);
        cancel();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          text: 'Please Fill Details Correctly',
          icon: 'error',
        });
      });
  };

  const UpdateShow = async (d) => {
    setshowRoom(true);
    setroom(true);
    console.log(d);
    setFloorName(d.floor_name);
    setStopic(d.subscribes_topic);
    setPtopic(d.publish_topic);
    setDtopic(d.device_topic);
    setMsg(d.unlock_message);
    setLockerNumger(d.locker_no);
    setZone(d.zone_id);
    setType(d.locker_type);
    setLockerDataOne(d);
  };

  const cancel = () => {
    setshowRoom(false);
    setFloorName('');
    setStopic('');
    setPtopic('');
    setDtopic('');
    setMsg('');
    setLockerNumger('');
    setZone('');
    setType('');
  };

  const cancel1 = () => {
    setshowZoneUpdate(false);
    setFloorName('');

    setStopic('');
    setPtopic('');
    setDtopic('');
    setMsg('');
    setLockerNumger('');
    setZone('');
    setType('');
  };

  const deleteLocker = async (d) => {
    var obj = {
      locker_id: d._id,
    };
    await locker
      .deletelocker(obj)
      .then(function (response) {
        // setFloor(response.data?.data?.floor)
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const AddUser = async (d) => {
    var obj = {
      locker_id: LockerDataOne._id,
      employee_id: invite[0]._id,
    };

    await locker
      .adduser(obj)
      .then(function (response) {
        // setFloor(response.data?.data?.floor)
        Toast.fire({
          icon: 'success',
          title: response?.data?.data?.message,
        });
        getData();
        setUser(false);
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const RemoveUser = async (d) => {
    var obj = {
      locker_id: d._id,
    };
    await locker
      .removeUser(obj)
      .then(function (response) {
        // setFloor(response.data?.data?.floor)
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        getData();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const showAddUser = (d) => {
    setLockerDataOne(d);
    setUser(true);
  };
  const openLock = async (d) => {
    // eslint-disable-next-line no-restricted-globals
    let ifok = confirm('Are You Sure To Open Locker');
    if (ifok) {
      var obj = {
        locker_id: d._id,
      };
      await locker
        .openlocker(obj)
        .then(function (response) {
          Toast.fire({
            icon: 'success',
            title: response?.data?.data?.message,
          });
          setTimeout(() => {
            getData();
          }, 3000);
        })
        .catch(function (error) {
          console.log(error.response.data.data.message);
          popUp.fire({
            title: error?.response?.data?.data?.message,
            icon: 'error',
          });
        });
    } else {
      alert('You Revert The Process');
    }
  };

  const deleteZone = async (d) => {
    var obj = {
      zone_id: d._id,
    };
    console.log(obj);
    await locker
      .deletezone(obj)
      .then(function (response) {
        // setFloor(response.data?.data?.floor)
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        // getData()
        getZone();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  const UpdateShow2 = async (d) => {
    setZoneName('');
    setZoneId('');
    setZonePassword('');
    setZoneUpId('');
    setshowZoneUpdate(true);
    setroom1(false);
    console.log(d);
  };
  const UpdateShow3 = async (d) => {
    console.log(d);
    setZoneName(d.name);
    setZoneId(d.username);
    setZonePassword(d.password);
    setZoneUpId(d._id);
    setroom1(true);
    setshowZoneUpdate(true);
  };

  const createZone = async () => {
    if (ZoneName === '' || ZoneName === null || ZoneName === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Zone Name',
      });
    } else if (ZoneId === '' || ZoneId === null || ZoneId === undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Zone Email',
      });
    } else if (
      ZonePassword === '' ||
      ZonePassword === null ||
      ZonePassword === undefined
    ) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Zone Password',
      });
    } else {
      var obj = {
        // "zone_id": "638e36b31ef7e2e5e1f7bc45",
        zone_name: ZoneName,
        username: ZoneId,
        password: ZonePassword,
        authentication: [
          {
            auth_id: '6362a43c03a71f9735fded34',
            auth_name: 'passcode',
          },
          {
            auth_id: '6362a43303a71f9735fded30',
            auth_name: 'RFID',
          },
          {
            auth_id: '638f88baeb8dc2581c31dab2',
            auth_name: 'QRscan',
          },
          {
            auth_id: '6362a44403a71f9735fded38',
            auth_name: 'face authentication',
          },
        ],
      };
      await locker
        .createzone(obj)
        .then(function (response) {
          Toast.fire({
            title: 'success!',
            text: response?.data?.data?.message,
            icon: 'success',
            confirmButtonText: "Let's go",
          });
          // getData()
          getZone();
          // setshowZoneUpdate(false)
          cancel1();
        })
        .catch(function (error) {
          popUp.fire({
            title: error?.response?.data?.data?.message,
            icon: 'error',
          });
        });
    }
  };

  const UpdateZone = async (d) => {
    // setshowRoom(true)
    // setroom(true)
    var obj = {
      zone_id: ZoneUpId,
      zone_name: ZoneName,
      username: ZoneId,
      password: ZonePassword,
      authentication: [
        {
          auth_id: '6362a43c03a71f9735fded34',
          auth_name: 'passcode',
        },
        {
          auth_id: '6362a43303a71f9735fded30',
          auth_name: 'RFID',
        },
        {
          auth_id: '638f88baeb8dc2581c31dab2',
          auth_name: 'QRscan',
        },
        {
          auth_id: '6362a44403a71f9735fded38',
          auth_name: 'face authentication',
        },
      ],
    };

    await locker
      .updatezone(obj)
      .then(function (response) {
        Toast.fire({
          title: 'success!',
          text: response?.data?.data?.message,
          icon: 'success',
          confirmButtonText: "Let's go",
        });
        // getData()
        getZone();
        setshowZoneUpdate(false);
        cancel1();
      })
      .catch(function (error) {
        popUp.fire({
          title: error?.response?.data?.data?.message,
          icon: 'error',
        });
      });
  };
  useEffect(() => {
    if (location.pathname === '/locker') {
      document.getElementById('Locker').style.backgroundColor = '#394A7A';
    }
    if (!renderAfterCalled.current) {
      detData();
      getData1();
      getFoolr();
      getData();
      getType();
      getZone();
      sockets.off('lockerupdate').on('lockerupdate', async (data) => {
        if (data) {
          getData();
          console.log('reload locker status');
        }
      });
       sockets.off('lockeravailable').on('lockeravailable', async (data) => {
        if (data) {
          getData();
          console.log('reload locker employee status');
        }
      });
    }

    renderAfterCalled.current = true;
  }, []);

  useEffect(() => {
    if (filterLocker === 'All') {
      getData();
    } else {
      filterData();
    }
  }, [filterLocker, filterZone]);
  return (
    <>
      <div className='appHeader'>
        <p>Facility Master</p>
      </div>
      <div className='management-flex'>
        <div className='control-wraper'>
          <div className='report-btn'>
            <Link to='/master-facility'>
              <button
                id='MeetingRoom'
                className='add-new report-btn-item blue-btn-box active'
              >
                Meeting Room
              </button>
            </Link>
            <Link to='/locker'>
              <button
                id='Locker'
                className='add-new report-btn-item blue-btn-box active'
              >
                Locker
              </button>
            </Link>
            <Link to='/panel'>
              <button
                id='Panel'
                className='add-new report-btn-item blue-btn-box active'
              >
               Door Panel
              </button>
            </Link>
            <Link to='/desk'>
              <button
                id='Panel'
                className='add-new report-btn-item blue-btn-box active'
              >
                Hot Desking
              </button>
            </Link>
            {/* <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button>
                    <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button>
                    <button id='HotDesking' className='report-btn-item blue-btn-box active' >Meeting Room</button> */}
          </div>
        </div>
      </div>
      <div className=''>
        <div className='manament-table-header'>
          <div className='btn-divder'>
            <button className='add-new-btn' onClick={addLocker}>
              Add Locker
            </button>
            <button className='add-new-btn' onClick={addZone}>
              Add Zone
            </button>
            {/* <button className='add-new-btn' >Remove Locker</button> */}
            <div className='input-container'>
              <select
                name='cars'
                id='cars'
                className='input-field simple-select'
                onChange={(e) => setfilterZone(e.target.value)}
                value={filterZone}
              >
                <option value='' selected>
                  All
                </option>

                {ZoneList.map((d, i) => {
                  return (
                    <option key={i} value={d._id}>
                      {d.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className='input-container'>
              <select
                name='cars'
                id='cars'
                className='input-field simple-select'
                onChange={(e) => setfilterLocker(e.target.value)}
                value={filterLocker}
              >
                <option value='' selected>
                  All
                </option>

                {LockerType.map((d, i) => {
                  return (
                    <option key={i} value={d.name}>
                      {d.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        {/* <div className='justify-center'>
          <span className='divder'></span>
          </div> */}
        <div className='table-container '>
          <div className='locker-list'>
            {LockerData.length > 0 ? (
              LockerData.map((d, i) => {
                return (
                  <>
                    {/* <div key={i} onClick={()=>showAddUser(d)}>
                                
                               
                              <IoMdLock/>
                              <p>Locker {d.locker_no}</p>
                              <h6>{d.status}</h6>
                              </div>
                              <span className="lockerControll">
                                    <div>
                                    <AiOutlineDelete onClick={()=>deleteLocker(d)}/>
                                    <HiOutlineUserRemove onClick={()=>RemoveUser(d)}/>
                                    <BiEditAlt/>
                                    </div>

                                </span> */}

                    <div
                      key={i}
                      className={
                        d.status === 'Available'
                          ? 'Available'
                          : d.status === 'Occupied'
                          ? 'Occupied'
                          : null
                      }
                    >
                      <span>
                        <div>
                          <AiOutlineDelete onClick={() => deleteLocker(d)} />
                          <HiOutlineUserRemove onClick={() => RemoveUser(d)} />
                          <HiOutlineUserAdd onClick={() => showAddUser(d)} />

                          <BiEditAlt onClick={() => UpdateShow(d)} />
                        </div>
                      </span>

                      {d.locker_status === 'lock' ? (
                        <div className='unlock' onClick={() => openLock(d)}>
                          <IoMdLock />
                        </div>
                      ) : (
                        <IoMdUnlock />
                      )}
                      <p>Locker {d.locker_no}</p>
                      <h6>{d.status}</h6>
                      <div className='lockertype'>
                        {d.locker_type === 'Guest Locker' ? 'G' : 'E'}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <>No Locker Found</>
            )}
          </div>
        </div>
      </div>
      {showRoom && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Create Locker</h3>
              <div className='company-detail-list'>
                {/* <InputWithLable placeholder='Meeting Room Name' callback={(event)=>setRoomName(event.target.value)} value={RoomName}/> */}
                <InputWithLable
                  placeholder='Locker Number: '
                  type='number'
                  callback={(event) => setLockerNumger(event.target.value)}
                  value={LockerNumger}
                />
                <div className='input-withlable-container '>
                  <span>Floor</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setFloorName(e.target.value)}
                      value={FloorName}
                    >
                      <option value='' disabled selected>
                        Floor
                      </option>

                      {Floor.map((d, i) => {
                        return (
                          <option key={i} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className='input-withlable-container '>
                  <span>Zone</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setZone(e.target.value)}
                      value={Zone}
                    >
                      <option value='' disabled selected>
                        Zone
                      </option>

                      {ZoneList.map((d, i) => {
                        return (
                          <option key={i} value={d._id}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className='input-withlable-container '>
                  <span>LockerType</span>

                  <div className='input-container'>
                    <select
                      name='cars'
                      id='cars'
                      className='input-field simple-select'
                      onChange={(e) => setType(e.target.value)}
                      value={Type}
                    >
                      <option value='' disabled selected>
                        Locker Type
                      </option>

                      {LockerType.map((d, i) => {
                        return (
                          <option key={i} value={d.name}>
                            {d.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {/* <InputWithLable placeholder='Description:' callback={(event)=>setDescription(event.target.value)} value={Description}/> */}
                {/* <InputWithLable placeholder='Device Id: ' callback={(event)=>setEmail(event.target.value)} value={Email}/> */}
                {/* <InputWithLable placeholder='Password:' callback={(event)=>setPassword(event.target.value)} value={Password}/> */}
                {/* <InputWithLable placeholder='No of Packs: ' callback={(event)=>setPacks(event.target.value)} value={Packs}/> */}
                <InputWithLable
                  placeholder='subscribe Topic:'
                  callback={(event) => setStopic(event.target.value)}
                  value={Stopic}
                />
                <InputWithLable
                  placeholder='Publish topic:'
                  callback={(event) => setPtopic(event.target.value)}
                  value={Ptopic}
                />
                <InputWithLable
                  placeholder='Device Topic: '
                  callback={(event) => setDtopic(event.target.value)}
                  value={Dtopic}
                />
                <InputWithLable
                  placeholder='Message: '
                  callback={(event) => setMsg(event.target.value)}
                  value={Msg}
                />
                <div className='facility-section'>
                  <span className='header'>Authentication</span>
                  <div className='flex-column-with-row'>
                    <div>
                      {/* <div className='align-center'>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                <label htmlFor="vehicle1">Face Authentication</label>
                </div>
                <div className='align-center'>
                <input type="checkbox" id="vehicle2" name="vehicle2" value="Car"/>
                <label htmlFor="vehicle2">Passcode Authentication</label>
                </div>
                <div className='align-center'>
                <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat"/>
                <label htmlFor="vehicle3">RF ID</label>
                </div>
                <div className='align-center'>
                <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat"/>
                <label htmlFor="vehicle3">QR Scanner</label>
                </div> */}

                      {facility.map((d) => {
                        return (
                          <>
                            <div className='align-center'>
                              <input
                                type='checkbox'
                                id={d.auth_id}
                                name='vehicle3'
                                value={d.auth_name}
                                onChange={(e) => console.log(e.target.checked)}
                              />
                              <label htmlFor='vehicle3'>{d.auth_name}</label>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel}>
                    Cancel
                  </button>
                  {!room ? (
                    <button className='create-btn' onClick={createRoom}>
                      Create
                    </button>
                  ) : (
                    <button className='create-btn' onClick={UpdateRoom}>
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {user && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Add User</h3>
              <div className='company-detail-list'>
                {LockerDataOne.employee_id}
                <DropDownSelect
                  limit={1}
                  data={userdata}
                  remove={(s, r) => setInvite(s)}
                  callback={(d) => setInvite(d)}
                />
                <div className='btns'>
                  <button
                    className='create-btn cancel-btn'
                    onClick={() => setUser(false)}
                  >
                    Cancel
                  </button>
                  {/* !room ?<button className='create-btn' onClick={createRoom}>Create</button>: */}
                  <button className='create-btn' onClick={AddUser}>
                    AddUser
                  </button>
                  {/* } */}
                </div>
                {/* <button onClick={AddUser}>ADD</button>
        <button onClick={AddUser}>Close</button> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {showZone && (
        <div className='locker-zone-wrapper'>
          <h4>Locker Zone List</h4>
          <div className='zone-btn'>
            <button onClick={UpdateShow2}>Add New Zone</button>
            <span onClick={() => setshowZone(false)}>close </span>
          </div>
          <div className='zone-zontainer'>
            {ZoneList.map((d, i) => {
              return (
                <div key={i} id={d._id} className='zonelist'>
                  <span>{d.name}</span>
                  <span> {d.username}</span>
                  <span>{d.password}</span>
                  <span>
                    <AiOutlineDelete onClick={() => deleteZone(d)} />
                    <BiEditAlt onClick={() => UpdateShow3(d)} />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {showZoneUpdate && (
        <div className='add-company-wrapper'>
          <div className='add-company-container'>
            <div className='border-blue border-radius15px border-radius5px backgroundColor'>
              <h3>Create Zone</h3>
              <div className='company-detail-list'>
                {/* <InputWithLable placeholder='Meeting Room Name' callback={(event)=>setRoomName(event.target.value)} value={RoomName}/> */}
                <InputWithLable
                  placeholder='Zone Name'
                  callback={(event) => setZoneName(event.target.value)}
                  value={ZoneName}
                />
                <InputWithLable
                  placeholder='Zone Id'
                  callback={(event) => setZoneId(event.target.value)}
                  value={ZoneId}
                />
                <InputWithLable
                  placeholder='Password: '
                  callback={(event) => setZonePassword(event.target.value)}
                  value={ZonePassword}
                />
                <div className='facility-section'>
                  <span className='header'>Authentication</span>
                  <div className='flex-column-with-row'>
                    <div>
                      {facility.map((d) => {
                        return (
                          <>
                            <div className='align-center'>
                              <input
                                type='checkbox'
                                id={d.auth_id}
                                name='vehicle3'
                                value={d.auth_name}
                                onChange={(e) => console.log(e.target.checked)}
                              />
                              <label htmlFor='vehicle3'>{d.auth_name}</label>
                            </div>
                          </>
                        );
                      })}

                      {/* {!room1?facility.map((d,i)=>{
 return <><div className='align-center'>
                <input type="checkbox"   id={d.auth_id} name="facility" value={d.auth_name} onChange={(e)=>console.log(e)}/>
                <label htmlFor="vehicle3">{d.auth_name}</label>
   </div>
  </>
}):null

} */}

                      {/* {access.map((a,i)=>{
 return <div className='align-center' key={i}>
                <input type="checkbox" checked   id={a.auth_id} name="facility" value={a.auth_name} onChange={(e)=>console.log(e)}/>
                <label htmlFor="vehicle3">{a.auth_name}</label>
   </div>
 })} */}
                    </div>
                  </div>
                </div>
                <div className='btns'>
                  <button className='create-btn cancel-btn' onClick={cancel1}>
                    Cancel
                  </button>
                  {!room1 ? (
                    <button className='create-btn' onClick={createZone}>
                      Create
                    </button>
                  ) : (
                    <button className='create-btn' onClick={UpdateZone}>
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Locker;
